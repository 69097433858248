.whiteboard {
  height: 100%;

  #toolbar {
    --selected-icon-bg-color: transparent;
    padding-right: 30px !important;

    .btn-group {
      background: transparent;

      button {
        background: #fff;
        border: 0;
        color: #757575;

        svg {
          color: inherit;
        }
      }

      button ~ button {
        border-left: 1px solid transparent;
      }

      button:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.basicalert {
  font-family: inherit !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  top: 0 !important;
  position: fixed !important;
  z-index: 1300 !important;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    border: none !important;

    > div:first-child {
      background: none !important;
      margin: 0;
      padding: 16px 24px !important;
      font-size: 1.25rem !important;
      font-weight: 500 !important;
      line-height: 1.6 !important;
      letter-spacing: 0.0075em !important;
      border: none !important;

      > div {
        margin: 0 !important;
      }
    }

    > div:nth-child(2) {
      padding: 8px 24px !important;
      font-size: 1.15rem !important;
    }

    > div:last-child {
      height: unset !important;
      display: flex;
      padding: 8px !important;
      align-items: center;
      justify-content: flex-end;

      button {
        padding: 6px 8px !important;
        font-weight: 500 !important;
        line-height: 1.75 !important;
        border-radius: 4px !important;
        min-width: 64px !important;
        color: var(--primary-main) !important;
        background-color: rgba(0, 0, 0, 0) !important;
        margin: 0 !important;
        -webkit-appearance: unset !important;
      }
    }
  }
}