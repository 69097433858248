body.blur {
    ul.navigation > *,
    div.footer-nav > ul,
    div.App-pageContainer > div > div:last-child,
    .notification-bell,
    .plus-btn-client,
    .client-dashboard-guideline-icon,
    #addPolicyTitle,
    .nav-home-btn,
    .nav-item,
    .analysis-input-panel {
        filter: blur(2px);
    }
}

.client-nav-bar-drawer {
    > div[aria-hidden="true"] {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    > div[tabindex="-1"] {
        background: transparent;
        width: 100%;
        height: 100%;
        box-shadow: none;
        overflow: hidden;

        .client-nav-bar-btn {
            width: 100%;
            min-width: 135px;
            justify-content: start !important;
            font-size: 17px !important;
            color: #fff;

            img {
                width: 75px;
                margin: auto;
                background: #fff;
                border-radius: 50%;
            }
        }
    }
}
