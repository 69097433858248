:root {
  --my-shadow: 0 3px 5px 0 rgba(109, 109, 109, 0.16);
  //--my-gradient: linear-gradient(135deg, #33ccff ,#3966f8);
  --my-gradient: linear-gradient(138deg, #3999f8 28% ,#3966f8 68%);
  --new-theme: #3966f8;
  --top-padding: 0;
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --top-padding: env(safe-area-inset-top);
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  :root {
    --bottom-padding: max(0px, calc(env(safe-area-inset-bottom) - 15px));
  }
}
