.image-title-card-btn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: solid 1px #ddd;
  box-shadow: 0 3px 5px 0 rgba(109, 109, 109, 0.27) !important;
  border-radius: 5px !important;

  img {
    height: 70px;
    width: 70px;
  }


  .item-index {
    color: #3966f8;
    background-color: #ffffff;
    font-weight: 500;
    width: 19px;
    height: 19px;
    margin-right: 4px;
    border: solid #3966f8 1px;
  }

  .content {
    padding: 10px;
  }
}

.image-title-card-btn:hover {
  background: #edfeff;
  cursor: pointer;
}