.my-profile-user {
  .user-account-details-card {
    > div {
      > div {
        margin-bottom: 8px;
      }
    }

    p, a {
      color: #858585;
      font-weight: 500;
    }
  }
}