.policy-review-container.present-mode {
  padding: 0 16px;
  min-height: 100vh;
  background: #fff;
}

.policy-review-container.negative-margin {
  margin-left: -60px;
}

.policy-review-container:not(.present-mode) {
  .pdf-template {
    border: 3px solid #c08fed;
    padding: 8px;
    background: #fff;
  }
}

.policy-review-container {
  padding: 60px 16px calc(50px + var(--bottom-padding)) 16px;
  overflow-x: hidden;
  font-size: 13px;

  .aladdin-hint {
    margin-right: unset;
    margin-left: unset;
  }

  > div {
    .policy-review-step {
      margin: 12px 0;
      width: fit-content;
      padding-bottom: 4px;
    }

    margin-bottom: 15px;
  }
}