.paymentMethodInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.pricingCardFullWidth {
    width: 100%;
}

.pricingCardWidth {
    width: 80%;
    @media (min-width: 600px) {
        width: 40%;
        max-width: 500px;
    }
}