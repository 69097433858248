.PasswordRulesInfoContainer {
  margin: 12px;
}

.PasswordRule {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.PasswordRuleName {
  margin-left: 4px;
}
