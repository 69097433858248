.max-width-wrapper {
	max-width: 468px;
	margin: 0 auto;
	width: 100%;
}

.new-sign-up {
	#wrapper {
		height: 100%;
	}

	#page-content-wrapper {
		padding-bottom: 0;
	}

	.stepper {
		background-color: transparent;
		padding: 24px 0;

		.step:first-child {
			padding-left: 0;
		}

		.step:last-child {
			padding-right: 0;
		}

		.step {
			padding: 0 16px;

			span {
				span:first-child {
					padding-right: 0;

					svg[class*="active"] {
						color: #ffffff;
					}

					svg {
						width: 2em;
						height: 2em;
						color: rgba(255, 255, 255, 0.5);

						text {
							font-size: 1rem;
							fill: #3966f8;
							font-weight: bold;
						}
					}
				}
			}
		}

		.step-connector {
			span {
				border-color: #ffffff;
				border-top-width: 2px;
			}
		}
	}

	.section, .pricing-wrapper {
		> p {
			margin-top: 15px;
		}
	}
}

@media screen and (min-width: 960px) {
	.new-sign-up {
		//position: fixed;
		//width: 100vw;
		//height: 100vh;
		//overflow: auto;
		//left: 0;
	}
}