.referral-qr-code-dialog {
  [role="dialog"] {
    > div::-webkit-scrollbar {
      display: none;
    }
  }

  .referral-card-wrapper .logo-wrapper > div img {
    max-width: 280px;
  }
}