.saving-annuity {
	.saving-annuity-slide {
		position: relative;
		text-align: center;

		img {
			max-width: calc((100vh - 150px) * 0.65);
		}
	}

	.slide-fake-button {
		position: absolute;
		top: 0;
		height: 100%;
		width: 50%;
		z-index: 1;
	}

	.slide-fake-button:not(:first-child) {
		left: 50%;
	}
}