.my-profile-adviser {
  .adviser-account-details-card {
    > div {
      > div {
        margin-bottom: 8px;
      }
    }

    p, a {
      color: #757575;
      font-weight: 500;
    }

    .adviser-info {
      > div {
        flex-wrap: nowrap;
        align-items: flex-start;

        .adviser-info-left {
          padding-top: 0;
          padding-left: 0;
        }

        .adviser-info-qr-right {
          #qr-code {
            width: 100% !important;
            max-width: 150px !important;
            min-height: 50px !important;
          }
        }
      }
    }
  }
}
