.share-wif-adv {
  min-height: 100vh;
  background: #fff;

  .img-wrapper {
    img {
      width: 100%;
    }
  }

  .messages {
    border: 1px solid #3966f8;
    border-radius: 5px;
    margin: 15px;
    padding: 8px;

    p {
      white-space: pre-wrap;
    }
  }

  div.actions {
    padding: 0 15px;

    button {
      width: 100%;
    }
  }
}