.last-meeting-hint-row {
  display: inline-flex;
  height: inherit;

  > :first-child {
    height: inherit !important;
    width: 50px;
    margin: 2.8px 20px 2.8px 0;
  }

  > span {
    min-width: 150px;
  }
}