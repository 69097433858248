.red__border--up {
    border-left: 4px solid rgb(255, 106, 116) !important;
    border-radius: 6px !important;
}
.red__border--down {
    margin-top: 0;
    border-left: 3px solid rgb(255, 106, 116) !important;
}
.green__border--up {
    border-left: 4px solid rgb(94, 224, 146) !important;
    border-radius: 6px !important;
}
.green__border--down {
    margin-top: 0;
    border-left: 3px solid rgb(94, 224, 146) !important;
}
.gray__border--up {
    border-left: 4px solid rgb(204, 204, 204) !important;
    border-radius: 6px !important;
}
.gray__border--down {
    margin-top: 0;
    border-left: 3px solid rgb(204, 204, 204) !important;
}
.yellow__border--up {
    border-left: 4px solid rgb(255, 175, 25) !important;
    border-radius: 6px !important;
}
.yellow__border--down {
    margin-top: 0;
    border-left: 3px solid rgb(255, 175, 25) !important;
}




.red__border--right {
    border-right: 4px solid rgb(255, 106, 116) !important;
    border-radius: 6px !important;
}
.green__border--right {
    border-right:4px solid rgb(94, 224, 146) !important;
    border-radius: 6px !important;
}
.gray__border--right {
    border-right: 4px solid rgb(204, 204, 204) !important;
    border-radius: 6px !important;
}
.yellow__border--right {
    border-right: 4px solid rgb(255, 175, 25) !important;
    border-radius: 6px !important;
}


// open new css for collapse
.bodyscrollhide {
    overflow-y: hidden;
}
// .client-stage-tabs + div {
//     height: calc(100vh - 150px);
//     max-height: 100vh;
//     width: calc(100% + 4px);
// }
@media (max-width: 1023px) {
    .client-stage-tabs {
        position: fixed;
        background-color: #f5f5f5;
        width: 100vw;
        z-index: 1;
    }
}

@media (min-width: 960px) {
    .client-stage-tabs {
        width: calc(100vw - 60px);
    }
    .client-stage-tabs.left-tab{
        width: unset;
    }
}
.todoImages {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    vertical-align: top;
}
.nodatafoundhead {
    margin-bottom: 15px !important;
    padding-bottom: 15px !important;
}
.policymarg {
    margin-left: 5px;
}
.img__relative {
    position: relative;
    float: right;
}
.img__absolute {
    position: absolute !important;
    right: 0;
    top: 1px;
    .MuiBadge-dot {
        top: 0 !important;
        right: 3px !important;
    }
}
.zeromargnew {
    margin-bottom: 0 !important;
    margin-top: -10px;
    .marg__toggle--lr {
        .MuiSwitch-root {
            margin-top: -10px;
        }
    }
}
.trackbiz__label {
    position: relative;
    top: -5px;
}
.main_heading--done {
    font-size: 18px;
    font-weight: normal;
    color: #3966f8;
    margin: 10px 0 10px 15px;
}
// .adviser-dashboard-slide {
//     background: #fff;
// }
.assistant-body{
  overflow: hidden;
}
//
// .adviseropportunityEdit.htautoEdit {
//     .react-swipeable-view-container {
//         max-height: calc(100vh - 186px) !important;
//     }
// }

// @media (max-width: 767px){
//   .adviseropportunityEdit.htautoEdit {
//       .react-swipeable-view-container {
//           max-height: calc(100vh - 186px) !important;
//       }
//   }
// }


.main_heading {
    position: relative;
    font-size: 18px;
    font-weight: normal;
    color: #3966f8;
    margin: 10px 15px 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .MuiSvgIcon-root {
        font-size: 20px;
    }
}
.main_subheading{
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);
    margin: 0 15px 60px 15px;
    white-space: pre-line;
    word-wrap: break-word;
}
.collapsedetails {
    .MuiTypography-colorTextSecondary {
        width: 100%;
    }
}
.collapseup {
    width: 100%;
    float: left;
    border-radius: 8px;
    margin-top: 2px;
    .collapseup__left {
        width: 50%;
        display: inline-block;
        img {
            width: 20px;
            margin-right: 0;
        }
        h2 {
            font-size: 14px;
            color: #000;
            font-weight: normal;
            margin: 20px 0 0 0;
            padding: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                width: auto;
                margin-right: 10px;
                img {
                    width: 20px;
                    margin: 0;
                }
            }
        }
    }
    .collapseup__right {
        width: 50%;
        display: inline-block;
        img {
            width: 20px;
            margin-left: 5px;
        }
        h2 {
            font-size: 14px;
            color: #000;
            font-weight: normal;
            margin: 0 0 20px 0;
            padding: 0;
        }
        .collapseup__right--down {
            width: 100%;
            margin-top: 0;
            float: left;
            span {
                background: #3966f8;
                padding: 2px 1px 0 2px;
                color: white;
                border-radius: 6px;
            }
            .rightimg {
                float: right;
                margin: 0;
            }
        }
    }
}
.pendingbutton {
    background: #3966f8 !important;
    margin-right: 3px !important;
    padding: 4px 2px 4px 2px !important;
    font-size: 14px !important;
    color: #fff !important;
    border: 0 !important;
    font-weight: 300 !important;
    line-height: 15px !important;
    min-width: 15px !important;
    text-transform: capitalize !important;
    span {
        background: none !important;
        padding: 0 !important;
    }
    &:hover {
        background: #3966f8 !important;
    }
}
.collapsedown {
    display: flex;
    justify-content: space-between;
    float: left;
    width: 100%;
    margin: 12px 0;
    .rightimg {
        float: right;
        margin: 0 !important;
    }
    .collapsedown__left {
        border-radius: 8px;
        img {
            width: 20px;
            margin-right: 0;
        }
        h2 {
            font-size: 14px;
            color: #000;
            font-weight: normal;
            margin: 0 0 0 0;
            padding: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                width: 100%;
                margin-bottom: 22px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .collapsedown__right {
        border-radius: 8px;
        padding: 10px 12px;
        width: 50%;
        margin-left: 5px;
        img {
            width: 20px;
            margin-right: 0;
        }
        .zeromarg {
            margin-bottom: 0 !important;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                width: 100%;
                margin-bottom: 22px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.clearfix {
    clear: both;
}
.shortfall__details {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 10px 0;

    .clientName {
        width: 50%;
        .client-shortfall {
            width: 100%;
            .client-shortfall-titles {
                width: 48px;
                .client-shortfall-title-main {
                    width: 100%;
                    text-align: center !important;
                }
            }
            .client-shortfall-values {
                width: calc(100% - 48px);
            }
        }
    }
    // .label__title {
    //     width: 50px;
    //     color: #3966f8;
    //     font-weight: bold;
    //     text-align: left;
    //     padding-left: 10px;
    // }
    // .label__details {
    //     width: calc(100% - 60px);
    //     color: rgb(255, 0, 0);
    //     text-align: center;
    // }
}
.labelwidth {
    width: 90%;
    margin: 0 !important;
    .MuiButtonBase-root {
        padding: 0 2px 0 0 !important;
    }
    .MuiFormControlLabel-label {
        font-size: 13px;
            word-break: break-word;
    white-space: pre-line;
    }
    .MuiSvgIcon-root {
        font-size: 20px;
    }
}
.labelwidthsmall {
    max-width: 20px;
}
.all__icons--wrapper {
    .centerpull {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        color: #3966f8;
        margin: 0;
    }
    .leftpull {
        width: calc(100% - 80px);
        float: left;
        text-align: left;
        padding-left: 50px;
        img {
            width: 20px;
        }
    }
    .rightpull {
            // width: 80px;
            float: right;
            text-align: right;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                width: auto;
                float: right;
                margin: 0 4px;
                &:first-child {
                    margin-right: 0;
                }
                img {
                    width: 20px;
                }
            }
        }
    }
}
.reactswipeableviewcontainer {
    .MuiFormControl-root {
        margin-bottom: 0 !important;
    }
    .MuiExpansionPanelSummary-expandIcon {
        padding: 5px 0px 5px 0px !important;
        margin-right: -5px;
        position: relative;
        width: 30px;
        height: 30px;
        &::after {
            content: '';
            // background-image: url('/img/downArrowBlue.png');
            // background-size: 18px;
            // width: 20px;
            // height: 20px;
            // background-repeat: no-repeat;
        }
        .MuiIconButton-label {
            // display: none;
            .MuiSvgIcon-root {
                font-size: 30px;
                color: #3966f8;
            }
        }
    }
    .adviser-dashboard-slide {


        .MuiExpansionPanel-root {
            margin-bottom: 5px;
            margin-top: 5px !important;
            box-shadow: 0px 2px 6px 1px rgba(109, 109, 109, 0.33);
            border-radius: 5px;
            &::before {
                content: '';
                height: 0;
            }
            .MuiExpansionPanelSummary-root {
                border-radius: 0;
                // border-left: 3px solid #ff6a74;
                // margin-left: 1px;
            }
            .MuiExpansionPanelSummary-root.Mui-expanded {
                min-height: 50px;
                border-radius: 0;
                // border-left: 3px solid #ff6a74;
                // margin-left: 1px;
            }
            &:last-child {
              margin-bottom: 0 !important;
            }
        }
        .MuiExpansionPanelSummary-root {
            padding: 0 7px 0 5px;
        }
    }
}
// .react-swipeable-view-container {
//     height: auto !important;
// }
.nobord {
    border-left: 1px solid #d8d8d8 !important;
}
.innerdetails {
    padding: 0 7px !important;
    .MuiExpansionPanel-root {
        border-left: 4px solid #ff6a74 !important;
    }
}
// close new css for collapse



.plan__insurer-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
    // span{
    //     &:last-child{
    //        padding: 0 13px 0 0;
    //     }
    // }
}
.text-left {
    text-align: left
}
.text-center {
    text-align: center
}
.text-right {
    text-align: right
}
.text-justify {
    text-align: justify
}
hr {
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}
footer {
    border-top: 1px solid #c2c2c2;
    height: 56px;
    line-height: 56px;
    background-color: #fff;
    position: relative;
    margin-bottom: -18px;
    width: 100%;
    z-index: 99;
}

.logo-title{
    font-weight: 400;
    letter-spacing: 0.85px
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover{
    border-color: #4990e2 !important;
}

.premium__btns{
    position: absolute;
    top: -17px;
    right: -15px;
    max-width: 180px;
    button{
        width: 100% !important;
        max-width: 85px !important;
        font-size: 11px;
        height: 22px !important;
        margin: 2px;
    }
}

.pagecontentwrapper {
    background: white;
    min-height: calc(100vh - 40px);
}

@media (max-width: 320px){
    .adviseropportunityEdit.htautoEdit {
        height: 100%;
    }
    //.plan__insurer-toggle{
        // span{
            //&:last-child{
               // padding: 0 13px 0 0;
                //margin-left: -16px;
           // }
       // }
   // }
}
// .advisor_team page
.advisor_team-wrapper{
    padding-bottom: 150px !important;
}

@media (max-width: 767px){
    .pagecontentwrapper {
        padding: 0 0 0px 0px !important;
    }
    .shortfall__details {
    .clientName {
        .client-shortfall {
            .client-shortfall-titles {
                .client-shortfall-title-main {
                    margin: 0;
                }
            }
            .client-shortfall-values {
                width: calc(90% - 48px);
            }
        }
    }
}
    .pendingbutton {
        padding: 3px 2px 3px 2px !important;
    }
    .leftpull {
        text-align: center !important;
        padding-left: 0 !important;
    }
    .labelwidth {
        width: 100%;
    }
}


.nav-top-margin {
}
// .progress__view {
//   .react-swipeable-view-container {
//     div + div[aria-hidden="false"] {
//       height: !important;
//     }
//   }
// }
.main__swipe__view {
}
.swipe__view__inner {
  .react-swipeable-view-container {
    // background: red;
    min-height: inherit !important;

  }
}
@media (max-width: 1023px) {
    .swipe__view__inner {
        margin-top: 45px;
      }
}
