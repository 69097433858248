.referral-records-page {
  .reminder-box {
    margin-top: 15px;
    padding-bottom: 15px;

    .referral-header, .referral-record {
      margin: 4px 0;
      box-shadow: 0 3px 5px 0 rgba(109, 109, 109, 0.16);
    }

    .referral-record {
      word-break: break-all;

      .summary {
        > div:first-child {
          margin: 0;

          .add-wrapper {
            button {
              margin-left: -12px;
            }
          }
        }

        .Badge {
          span {
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
          }
        }

        > div:last-child {
          position: absolute;
          right: 12px;
        }
      }

      .details {
        padding: 0 10px;

        ul {
          padding: 0;

          li {
            padding: 10px 0;
            // color: #888;
            font-size: 14px;

            > div {
              > :first-child {
                text-align: right;
              }
            }

            .action {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }
}