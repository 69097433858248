.choose-type-wrapper {
    height: calc(100vh - 40px);
}

.type-options {
    margin-top: 10px;
    button {
        min-height: 50px;
        margin-right: 15px;
    }
    > div {
        margin: auto auto 15px auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
    }
    @media screen and (min-width: 960px){
        display: flex;
        justify-content: space-around;
        margin-left: -100px;
        margin-top: 120px;
    }
}

.type-title {
    padding-top: 10px;
    @media screen and (min-width: 960px){
        padding-top: 50px;
        margin-left: -100px;
    }
}

.choose-type-login-wrapper {
    padding: 5px;
    padding-top: 88px;
    text-align: center;
    @media screen and (min-width: 960px){
        margin-left: -80px;
    }
}


.user-type-btn-content {
    overflow: hidden;
    img {
        width: 15vh;
    }
}