.error-view {
	height: 100vh;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	img {
		width: 80%;
		margin-bottom: 20px;
	}
}

.error-container {
	width: 100vw;
	position: fixed;
	overflow: scroll;
	left: 0;
	background-color: white;
}