.refer-adviser {
  .container {
    padding: 50px 16px 60px;
    text-align: center;

    section {
      margin: 15px 0;
    }

    .banner-section {
      img {
        width: 100%;
      }
    }

    .link-text-section {
      input {
        padding: 12px;
        color: #3966f8;
        background: #fff;
        font-size: 150%;
      }

      fieldset {
        border: 2px solid #3966f8;
      }
    }

    .copy-button-section {
      button {
        font-size: 125%;
      }
    }

    .link-text-section .link-text-field > div, .copy-button-section button, .rewards-section > div {
      border-radius: 10px;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }

    .rewards-section {
      > div {
        box-shadow: var(--my-shadow);

        .hint {
          padding-left: 12px;

          .de {
            font-size: 70%;
          }
        }
      }
    }

    .terms-section {
      p {
        font-size: 90%;
      }
    }
  }
}