.modalView.my-editor-modal {
  padding: 15px 10px;
}

.editor-modal-header {
  text-align: center;
  color: #3966f8;
  font-weight: bold;
  font-size: 150%;
}

.target-editor-modal:not(.open) .rdw-editor-toolbar {
  visibility: hidden !important;
}
