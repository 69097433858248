.my-details.adviser {
  .container {
    padding: 50px 0 85px 0;
    overflow-x: hidden;

    .swiper-slide {
      padding: 16px;

      label {
        font-weight: 400;
        color: #0000008a;
      }

      .field-label {
        font-weight: 400;
      }

      .field-label:not(.MuiTypography-colorError) {
        color: #0000008a;
      }

      :not(.phone-text-field) input {
        height: 2em;
      }

      .mc-expansion-panel {
        > div:first-child {
          padding: 0 12px;
          min-height: unset;

          > div {
            margin-bottom: 0;

            p {
              font-size: 1.2rem;
              font-weight: 400;
            }

            p:not(.MuiTypography-colorError) {
              color: #333;
            }
          }
        }

        > div:nth-child(2) {
          [role="region"] > div {
            padding: 0 12px 16px;

            > div {
              > div:first-child {
                p {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }

      [data-placeholder]::after {
        position: absolute;
        white-space: nowrap;
        left: 7px;
        top: 22px;
        content: attr(data-placeholder);
        pointer-events: none;
        opacity: 0.7;
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}