.portfolio-selector {
  height: 100%;
  padding: 6px 4px 0 4px;

  > p {
    font-weight: bold;
  }

  .scrollIcons {
    overflow: auto;

    > div {
      font-size: medium;
      font-weight: bold;
    }
  }

  .scrollIcons::-webkit-scrollbar {
    display: none;
  }
}