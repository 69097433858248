.policy-entry {
  padding: 10px 8px 10px 10px;

  > div {
    > :last-child {
      text-align: left;

      svg {
        vertical-align: middle;
      }
    }
  }

  .other-insurer {
    min-width: 70px;
    text-align: center;
  }

  .special-tag {
    width: 16px;
    height: 16px;

    > img {
      width: 100%;
      vertical-align: baseline;
    }
  }

  .vhisPlan, .qdapPlan {
    margin: 0 6px 0 0;
  }

  .policy-name {
    width: 100%;
    padding-right: 4px;
    text-overflow: ellipsis;
    //white-space: nowrap;
    overflow: hidden;
  }
}