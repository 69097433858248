.simplified-add-mpf {
  .MuiInput-formControl {
    min-height: 26px;

    input {
      min-height: 24px;
    }
  }

  p.MuiTypography-colorError.error, p.Mui-error {
    font-size: 0.85rem;
  }
}