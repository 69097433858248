.adviser-info-page.wide {
  .swiper-pagination {
    right: 0;
    width: unset;
  }
}

.adviser-info-page:not(.wide) {
  //.my-editor {
  //  max-height: 160px;
  //  overflow-y: auto;
  //}

  .card-container {
    .MuiCard-root {
      min-height: 440px;
    }
  }
}

.adviser-info-page {
  height: 100%;

  .emoji {
    margin-right: 8px;
  }

  .title {
    font-size: 13px;
  }

  .adviser-info-page-card {
    width: 300px;
    margin: 0 auto;
    position: relative;

    > div {
      border-radius: 3px 90px;
    }
  }

  .card-container {
    padding: 4px;

    > div {
      box-shadow: 5px 4px 8px 3px rgba(109, 109, 109, 0.5);
      overflow-y: auto;
    }

    .adviser-info-card-content {
      min-height: 480px;
      height: 100%;
    }
  }

  .swiper-container {
    padding: 10px 0;
  }

  @media screen and (min-width: 960px) {
    .swiper-button-next {
      right: 40px;
    }

    .swiper-button-prev {
      left: 40px
    }
  }

  .swiper-slide.swiper-slide-active {
    .adviser-info-page-card {
      transform: translateZ(1px);
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    filter: blur(4px);
    pointer-events: none;
    //opacity: 0.9;
  }

  .pagination-container {
    position: relative;
    height: 16px;

    .swiper-pagination {
      //bottom: -10px;
      width: 100%;

      .swiper-pagination-bullet {
        margin: 0 8px;
        width: 8px;
        height: 8px;
        border-width: 2px;
        border-style: solid;
        opacity: 1;
      }
    }
  }

  .pagination-container {
    .swiper-pagination-bullet {
      background: var(--biz-page-blue3);
      border-color: var(--biz-page-blue3);
    }

    .swiper-pagination-bullet-active {
      background: var(--biz-page-blue4);
    }
  }

  .swiper-slide:not(.swiper-slide-visible) {
    visibility: hidden;
  }

  .swiper-button-prev {
    left: 13px;
  }

  .swiper-button-next {
    right: 13px;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: 'slick';
    font-size: 22px;
  }

  .swiper-button-prev:after {
    content: '←';
  }

  .swiper-button-next:after {
    content: '→';
  }

  .swiper-button-prev, .swiper-button-next {
    color: var(--biz-page-blue1);
    background: #fff;
    border-radius: 50%;
    //top: 280px;
  }

  .orange {
    .pagination-container {
      .swiper-pagination-bullet {
        background: var(--biz-page-orange3);
        border-color: var(--biz-page-orange3);
      }

      .swiper-pagination-bullet-active {
        background: var(--biz-page-orange4);
      }
    }

    .swiper-button-prev, .swiper-button-next {
      color: var(--biz-page-orange1);
    }
  }

  .violet {
    .pagination-container {
      .swiper-pagination-bullet {
        background: var(--biz-page-violet3);
        border-color: var(--biz-page-violet3);
      }

      .swiper-pagination-bullet-active {
        background: var(--biz-page-violet4);
      }
    }

    .swiper-button-prev, .swiper-button-next {
      color: var(--biz-page-violet1);
    }
  }

  .earth {
    .pagination-container {
      .swiper-pagination-bullet {
        background: var(--biz-page-earth3);
        border-color: var(--biz-page-earth3);
      }

      .swiper-pagination-bullet-active {
        background: var(--biz-page-earth4);
      }
    }

    .swiper-button-prev, .swiper-button-next {
      color: var(--biz-page-earth1);
    }
  }

  .aip-card-footer-container {
    width: 100%;
    position: relative;
  }
}
