.my-medical-card-card {
  p, a {
    color: #858585;
    font-weight: 500;
  }

  .card-tabs {
    min-height: unset;
    button {
      font-size: 100%;
      min-width: unset;
      min-height: unset;
      padding: 4px 12px;
      line-height: 1;
    }

    button[aria-selected="true"] {
      color: #1124b1;
    }

    button[aria-selected="false"] {
      color: #5562c3;
    }
  }
}