.policy-category-table.protection:not(.no-policy) {
  table {
    border-bottom: none !important;
  }
}

.policy-category-table.protection {
  table {
    border: 3px solid #fbcfd0 !important;

    tr.sub-header {
      th {
        position: relative;
        border-bottom: none;
      }
      th::after {
        content: '';
        position: absolute;
        bottom: -1.5px;
        left: 0;
        width: 100%;
        border-bottom: 3px solid #f3686d;
      }
    }
  }
}