.avg-prem-setting-dialog {
  form {
    .auto-switch {
      margin: 8px 0 -4px 0;

      > span:last-child {
        opacity: 0.54;
      }
    }

    > div {
      margin: 12px 0;
    }

    > div:not(.auto) {
      > div {
        > input, div {
          color: #3966f8;
        }
      }
    }

    > div.cov-text-field {
      display: block;

      > label {
        transform: unset;
        position: relative;
        width: 50%;
        display: inline-block;
      }

      > div {
        margin: 0;
        width: 50%;
        display: inline-block;

        input, div {
          text-align: center;
        }
      }
    }
  }
}