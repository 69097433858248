.itemContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.itemTitle {
	// REVIEW: Overriding as the theme file seems messy right now
	font-size: 1.4rem !important;
	text-overflow: ellipsis;
	overflow: hidden;
}


.mobileSliderContainer {
	display: flex;
	gap: 0px;
	flex-direction: row;
	overflow: scroll;
}
