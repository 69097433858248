.sort-by {
    padding-left: 17px;
    padding-top: 6px;
    padding-bottom: 6px;
    line-height: 24px;
    justify-content: flex-start !important;
}

.arrow svg{
    margin-right: 8px;
    margin-left: -3px;
}

.inactive {
    visibility: hidden;
}

.delete-icon {
    margin-left: -4px;
    margin-right: 10px;
}

.delete-all {
        padding-top: 5px;
}