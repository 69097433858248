.connect-wif-adv-dialog {
  .img-wrapper {
    text-align: center;
    img {
      max-width: 55vw;
      max-height: 50vh;
    }
  }

  p {
    white-space: pre-wrap;
  }

  .qr-code-option {
    margin-top: 10px;

    label {
      margin: 0;
    }
    .qr-code-wrapper {
      margin: 0;
    }
  }
}