.my-table-pagination {
  background: #ffffff;
}

.my-table-pagination tr {
  height: fit-content;
  font-size: 16px;
}

.my-table-pagination td {
  padding: 0;
}

.my-table-pagination td > div {
  height: fit-content;
  min-height: unset;
}

.my-pagination-select {
  select {
    height: 25px;
  }

  @media (max-width: 330px) {
    select {
      padding: 0 4px !important;
    }

    svg {
      display: none
    }
  }
}

.my-pagination-select-total {
  display: inline-flex;
}

.pagination-actions {
  flex-shrink: 0;
  font-size: 16px;
}

.pagination-actions {
  button {
    width: 24px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  button:first-child {
    // padding-left: 0;
  }

  button:last-child {
    // padding-right: 0;
  }
}
