.pro-required-card {
  margin-bottom: 15px;
}

.export-input-div {
  background-color: #fff;
  margin: 0 0 15px 0;
  padding: 10px;
  overflow: auto;
}

.export-select-form legend {
  font-size: 16px;
  border-bottom: none;
  text-align: center;
  margin: 5px 0;
}

.export-select-form > div {
  display: inline-block;
  flex-wrap: initial;
  flex-direction: initial;
}

.export-select-form
  > div.export-select-group
  > div:not(.export-remark-input):not(.toolbar-wrapper):not(.position-wrapper):not(.full-wid) {
  vertical-align: bottom;
  width: 50%;
  margin-bottom: 0 !important;
}

.export-policy-info-type-input {
  font-size: 1.3rem;
}

.export-policy-info-type-input > label {
  margin-right: -5px;
}

.export-policy-info-type-input > div {
  margin-top: 0 !important;
}

.export-button-group-right-wrapper {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.export-alternative-button + .export-button-group-right-wrapper {
  width: calc(100% - var(--alternative-button-width));
}

button.export-button {
  min-height: unset;
  text-transform: unset;
  padding: 6px 12px;
  font-size: 12px;
  margin: auto 0 auto 10px;
  height: 30px;
}

button.new-export-button {
  white-space: nowrap;
  min-height: unset;
  text-transform: unset;
  margin: 5px 0;
  font-size: 14px;
  min-width: 90px;
}

button.export-alternative-button {
  margin-left: 0;
  width: var(--alternative-button-width);
}

.export-button-group {
  /* font-size: 12px; */
  margin: 12px 0;
  width: 100%;
  --alternative-button-width: 91px;
}

.export-hint {
  font-size: 80%;
  text-align: center;
}

.export-input-div .my-editor .DraftEditor-root {
  min-height: 80px;
}
.client__sms {
  display: flex;
  align-items: center;
}
.client__sms .input-group-option-select {
  max-width: 30px;
}
.client__sms .client__sms--text {
  width: 100%;
  max-height: 125px !important;
  height: 120px;
  margin: 10px -3px 0px !important;
}
.help_badge_export > span {
  width: 8px;
  height: 8px;
  right: 2px;
  top: -10px;
}

.export-select-form .MuiFormControlLabel-root {
  height: 29px;
}

@media (max-width: 320px) {
  .client-sms-image {
    height: 20px !important;
    margin: 4px -12px;
    padding: 3px 3px 3px 3px;
  }
  .help_badge_export > span {
    width: 5px;
    height: 5px;
    right: -6px;
    top: -9px;
    min-width: 4px;
  }
}
