.yellow-hint {
  background: #ffd485;
  padding: 8px;
  border-radius: 8px;
  white-space: pre-wrap;

  p {
    text-align: center;
    font-weight: bold;
  }
}