.function-card {
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 10px;
  padding: 0;

  > div {
    border-radius: inherit;
  }

  button {
    padding: 0 !important;
    background-color: white;
    border-radius: inherit;
    box-shadow: 1px 1px 8px 1px #3966f828;
    border-radius: 8px 16px 16px 8px;
  }   

  button:hover {
    box-shadow: 3px 5px 12px 3px #3966f828;
    background-color: white;
    transform: translateY(-1px);
  }

  .MuiButton-contained:hover {
    background-color: transparent !important;
  }
  .forward-icon {
    // border-radius: 50%;
    // // background-color: #3966f8;
    color: #3966f8;
    height: 28px;
    width: 28px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right: 8px;
  }

  p {
    color: #616161;
  }

  .img-container {
    max-width: 20%;
    @media screen and (max-width: 499px) {
      max-width: 25%;
    }

    img {
      width: 100%;
      max-height: 68px;
      object-fit: contain;
    }
  }

    .title {
      font-size: 16px;
      font-weight: normal;
    }
    .sub-title {
      color: #0000008a;
    }

  .side-bar {
    width: 6px;
    height: 130px;
    border-radius: 16px 0 0 16px;
  }
}