.todo-btn {
    button {
        padding: 8px 8px 8px 8px;
        margin-top: 2px;
        color: #fff !important;
        svg {
            width: 27px !important;
            height: 27px !important;
            fill: #fff;
        }
    }
}
.extra-margin {
    margin-left: 23px;
    @media (min-width: 600px) {
        margin-left: 48px;
    }
}

.normal-margin {
    margin-left: 5px;
}

.notification-bell {
    padding: 8px !important;
    // margin-bottom: 4px !important;
    color: white !important;
    svg {
        width: 25px;
        height: 25px;
    }
}

.top-nav-icons {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1200;
    display: flex;
    align-items: center;
    @media (min-width: 600px) {
        right: 2px !important;
    }
}

.dashboard-icons {
    justify-content: flex-end;
}

.hide {
    display: none;
}

svg.top-copy-btn {
    fill: white;
}

.footer-nav {
    > ul {
        > li {
            cursor: pointer;
        }
    }
}