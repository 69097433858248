.strike-through {
    text-decoration: line-through;
}

.checkbox-color {
    color: #808080;
}

.todo-and-date {
    margin: 0px -10px 0px -25px;
    span {
        font-weight: normal !important;
    }
}

.todo-and-date .date-row {
    font-size: small;
    display: flex;
    .with-exclamation {
        padding-top: 2px;
    }
}

svg.exclamation {
    fill: red;
    margin-left: -5px;
    height: 18px !important;
    width: 18px !important;
}

svg.copy-btn {
    fill: #3966f8;
}