.payment-channels {
	position: relative;
	padding: 20px 16px 40px;

	.subscription-period-card {
		margin-top: 10px;
	}

	.payment-method-wrapper {
		margin-top: 20px;

		.stripe-logos {
			margin-top: 15px;
		}

		.stripe-hint {
			margin-top: 10px;
			padding: 4px 0;
			border-radius: 8px;

			.icon {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				svg {
					width: 100%;
				}
			}

			.text {
				font-size: 80%;
				opacity: 0.8;
			}
		}
	}
}