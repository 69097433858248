@media screen and (min-width: 960px) {
  .pdf-export-card {
    .lang-radio-group {
      justify-content: flex-start;
    }

    .lang-radio-group {
      > label:first-child > span:first-child {
        padding-left: 9px;
      }
    }
  }
}