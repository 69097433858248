.client-page-filter {
  .slider-container:not(.active) {
    > div, input, .slider {
      color: grey !important;
    }
  }

  .age-slider-container:not(.age-min-active) span[role="slider"][data-index="0"],
  .age-slider-container:not(.age-min-active) span[role="slider"][data-index="0"] span,
  .age-slider-container:not(.age-max-active) span[role="slider"][data-index="1"],
  .age-slider-container:not(.age-max-active) span[role="slider"][data-index="1"] span {
     color: grey !important;
  }

  .age-slider-container:not(.age-min-active):not(.age-max-active) {
    > div:first-child, > div > span > span:nth-child(2) {
      color: grey !important;
    }
  }

  .familyTag {
    display: inline-block;
    font-size: 12px;
    color: #ff570e;
    border-radius: 15px;
    padding: 1px 7px 0px 7px;
    margin: 2px 3px 3px 3px;
    box-shadow: 2px 2px 6px #bbbbbb;
    cursor: pointer;
  }
}