div.subscription-plan-card {
	border-radius: 8px;
	position: relative;
	width: 230px;
	box-shadow: none;

	.hot-pick {
		position: absolute;
		z-index: 1;
		font-weight: bold;
		font-size: 150%;
		width: 100%;
		text-align: center;
		color: #fff;
		padding: 5px 0 5px 0;
		line-height: 1;
		background: #ffa4a4;
	}

	.card-header {
		text-align: center;
		font-weight: bold;
		position: relative;
		padding: 0;

		> div > span {
			color: white;
			line-height: normal;
			font-size: 150%;
		}

		.card-header-inner {
			padding: 6px 20px 12px;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			.sub-price {
				font-size: 85%;
				text-decoration: line-through;
			}

			.price {
				font-size: 200%;
				font-weight: bold;
				line-height: 1.25;
				margin-bottom: -8px;
			}
		}
	}

	.card-content {
		padding: 12px 16px;
		text-align: left;

		ul {
			padding: 0;
			text-align: left;
			margin: 0;

			li {
				list-style: initial;
				margin: 0 0 5px 10px;
				font-weight: 500;
				opacity: 0.8;
			}
		}
	}

	.card-actions {
		padding: 0;
		border-radius: 0 0 8px 8px;

		button {
			padding: 5px;
			margin: 12px;
			font-size: 150%;
			font-weight: bold;
		}
	}
}

div.subscription-plan-card:not(.actionable) {
	.card-content {
		border-radius: 0 0 8px 8px;
	}
}