.assistant-customize-dialog {
	form {
		color: #3966f8;

		> div {
			> * {
				vertical-align: middle;
				font-size: unset;
			}

			.day-input {
				width: 30px;
				input {
					padding: 7px 8px;
				}
			}

			div.select-buttons-inline-div {
				display: inline-flex;
				float: unset;

				button {
					position: absolute;
				}

				button:not(:last-child) {
					right: 65px;
				}

				button:last-child {
					right: 30px;
				}
			}
		}

		> div:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}