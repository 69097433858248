.connection-dialog {
	.Sign-up-now {
		min-width: unset;
		padding: 2px 8px 1px 7px;
		border-radius: 3px;
		margin: 0 0 0 10px;
		box-shadow: none;
	}

	.content {
		.portfolio-add-button {
			margin-left: 0;
		}
	}
}