.container {
	padding-top: 16px;
}

.filesContainer {
	display: flex;
  gap: 12px;
	align-items: center;
	flex-flow: row wrap;
	margin-top: 16px;
}

.containerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 16px;
}

.containerHeader :first-child {
  margin-left: auto;
  margin-right: auto;
}

// .containerHeader > :nth-child(2) {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
// }
