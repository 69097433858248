.business__tab{
	::-webkit-scrollbar {
		width: 0px;  /* Remove scrollbar space */
		background: transparent; 
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1340px){
	.adviseropportunityEdit{
		overflow: hidden;
		height: calc(100vh - 45px);
		#assistant-category-0 {
			padding-left: 5px;
		}
		#assistant-category-0, #assistant-category-1 {
			overflow-y: scroll;
			height: calc(100vh - 45px);
			padding-bottom: 100px;
		}
		
	}
}

@media only screen and (min-width: 1024px){
	#assistant-category-1{
			flex-basis: 62%;
			max-width: 62%;
			.adviser-dashboard-slide {
				padding-left: 60px;
				padding-right: 60px;
			}
	}
	#assistant-tab-1 {
		flex-basis: 62%;
		max-width: 62%;
		padding-left: 60px;
		padding-right: 60px;
	}
	#assistant-category-0, #assistant-tab-0 {
		flex-basis: 38%;
		max-width: 38%;
}
}


@media only screen and (min-width: 1024px) {
	.adviserdashboardslideEdit, .adviseropportunityEdit {
		.client-padding-boxes{
			padding: 0;
		}
		.setting-icon-desktop{
			position: absolute;
			top: 12px;
			right: 6px;
			z-index: 1;
			:hover{
				cursor: pointer;
			}
		}
		.standard-content-tabs{
			@media screen and (min-width: 1340px) {
				.tabs button span {
					font-size: 15px;
					// font-weight: bold;
				}
			}
			.left-tab{
				padding-right: 5px;
				padding-left: 5px;
				.MuiTab-wrapper{
					align-items: flex-start !important
				}
			}
			.right-tab{
				padding-left: 5px;
			}
			.adviser-dashboard-slide {
				padding: 0px;
				.right-assistant{
					padding-left: 5px;
				}
				.left-assistant{
					padding-right: 5px;
					// padding-left: 5px;
	
				}
			}
			.client-right-stage-tabs{
				width: 100%;
			}
			.all__icons--wrapper .leftpull{
				padding: 0;
			}
		}
	} 
}

.dashboard-target-modal {
	.action {
		display: unset;
		min-width: 275px;

		.button-wrapper {
			margin-left: 0;
			button {
				width: 100%;
				font-size: inherit;
			}
		}
	}
}
.clientTodoBottom{
	margin-bottom: 0px !important;
	padding-bottom: 15px !important;
}

.title__text {
	min-width: 120px;
	display: inline-block;
	text-align: left;
}
.toggle__space {
	padding-right: 20px;
}
.client__name__label {
	width: auto !important;
}
.client__name {
	width: 80%;
}

.progress-card{
	margin:5px;
	box-shadow: 0px 3px 5px 0 rgba(109, 109, 109, 0.27) !important;
	padding: 10px 10px 7px 10px;
}

.progress-card-upper-shadow{
	margin:5px;
	box-shadow: 0px 1px 7px 1px rgba(109, 109, 109, 0.27) !important;
	padding: 10px 10px 7px 10px;
}

.progress-card-title{
	font-size: 15px;
    font-weight: 400;
}

.progress-card-number{
	font-size: 38px;
    font-weight: 800;
}

.progress-card-number-small{
	font-size: 29px;
	font-weight: 800;
	color: #00000061;
}


.progress-card-number-smaller{
	font-size: 25px;
	font-weight: 800;
	color: #00000061; 
}


.progress-card-unit{
	font-size: 19px;
}

.progress-card-unit-small{
	font-size: 15px;
	color: #00000061;
}

.progress-card-unit-smaller{
	font-size: 15px;
	color: #00000061;
}


.quarter-tracking-chart-title{
	position: absolute;
    left: 90px;
    text-align: center;
    top: 60px;
    width: 115px;
}

.quarter-tracking-chart-policy-number {
	position: absolute;
    left: 90px;
    text-align: center;
    top: 80px;
	width: 115px;
	font-size: 18px;
	color: #00000061;
	font-weight: 600;
}

.advise-business-policy-number {
	font-size: 18px;
	color: #00000061; 
	font-weight: 500;
}

.advise-business-policy-button{
	margin-top: -8px!important;
}

.MuiLinearProgress-bar{
	border-radius: 15px;
}
.customize-progress-bar {
	border-radius: 15px;
	height: 5px !important;
	background-color: #E1EDF7 !important;
}

.bar-red-color > .MuiLinearProgress-bar{
	background-color: #ff6a74 !important
}

.bar-yellow-color > .MuiLinearProgress-bar{
	background-color: #ffaf19 !important
}

.bar-green-color > .MuiLinearProgress-bar{
	background-color: #5ee092 !important
}

.bar-grey-color > .MuiLinearProgress-bar{
	background-color: #cccccc !important
}

.bar-blue-color > .MuiLinearProgress-bar{
	background-color: #3966f8 !important
}

.progress-card-more-info{
	color: #3966f8;
    background-color: #ffffff;
	box-shadow: unset;
}

.quarter-progress-description{
    font-size: 16px;
	color: #00000061 !important;
	text-align: center;
	line-height: 0.75;
}

.quarter-progress-emoji{
	margin: 8px 0px 8px 0px;
}
.card-title-dot{
	height: 19px !important;
    width: 15px !important;
    margin-bottom: -4px !important;
}

.progress-emoji{
	margin-bottom: -4px;
    margin-top: -8px;
    padding-top: 5px;
}

.edit-info-button{
	padding: unset !important;
    min-width: unset !important;
}

.narrow-btm-5{
	margin-bottom:-5px;
}

.policy-count-number{
	font-size: 38px;
	color: #3966f8;
	font-weight: 800;
}

.show-button{
	padding: 0px !important;
    margin-bottom: -6px !important;
    min-width: 45px !important;
}

.close-biz-modal-btn{
	padding: 2px 6px !important;
	width: 29px !important;
}

.opportunity-count-button{
	display: inline-block !important;
    min-height: 27px !important;
    min-width: 27px !important;
    border-radius: 3px !important;
    text-transform: capitalize !important;
    padding: 4px 4px 3px 4px !important;
    box-shadow: none !important;
	line-height: 1.4 !important;
	color: #ffffff!important;
}
.pt-10{
	padding-top:10px !important;
	padding-right:10px !important;
}

.quarter-progress-chart{
	left: -50px;
	position: relative;
}

.quarter-progress-chart-right-card{
	box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0)!important;
	margin-top: -10px;
	margin-left: 20px;
}

.progress-card-more-info-btn{
	position:relative!important;
	padding:0px!important;
    // padding-top: 0px!important;
    // padding-bottom: 0px!important;
}

.follow-up-oppo-btn {
    display: inline-block !important;
    height: 29px !important;
    width: 29px !important;
    border-radius: 3px !important;
    // text-transform: capitalize !important;
    padding: 2px 2px 0px 2px !important;
    box-shadow: none !important;
    line-height: 1.4 !important;
	background: #ffffff !important;
	font-weight: 800 !important;
	margin:0!important;
    min-height: 29px !important;
    min-width: 29px !important;
    text-transform: none !important;
}

.follow-up-oppo-btn-small {
    border-radius: 3px !important;
    padding: 2px 2px 0px 2px !important;
    margin: 0 0 0 5px !important;
    min-height: 18px !important;
    min-width: 18px !important;
	font-weight: 800 !important;
    font-size: 10px !important;
}

.follow-up-biz-vol{
	text-align: right!important;
    margin-right: 6px!important;
	margin-left: -6px!important;
}

.follow-up-biz-vol-num{
	margin-left: -10px;
    color: #00000061;
    font-weight: 500;
    font-size: 15px;
}

.follow-up-case{
	text-align: left!important;
    margin-right: -6px!important;
    margin-left: 6px!important;
}

.follow-up-case-num{
    color: #00000061;
    font-weight: 500;
    font-size: 15px;
}


@media only screen and (max-width: 320px) {
	.quarter-progress-chart{
		left: -70px;
	}
	.follow-up-biz-vol-num{
		font-size: 13px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
	.quarter-progress-chart{
		left: -60px;
	}
	.quarter-progress-chart-right-card {
		margin-top: -10px;
		margin-left: 20px;
	}
	.follow-up-biz-vol-num{
		font-size: 13px;
	}
}

@media only screen and (min-width: 375px) and (max-width: 415px) {
	.quarter-progress-chart{
		left: -50px;
	}
	.follow-up-biz-vol-num{
		font-size: 14px;
	}
}

@media only screen and (min-width: 415px) and (max-width: 599px) {
	.quarter-progress-chart{
		left: -20px;
	}
	.follow-up-biz-vol-num{
		font-size: 14px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 675px) {
	.quarter-progress-chart{
		left: -80px;
	}
	.follow-up-biz-vol-num{
		font-size: 13px;
	}
}

@media only screen and (min-width: 675px) and (max-width: 750px) {
	.quarter-progress-chart{
		left: -40px;
	}
}

@media only screen and (min-width: 750px) and (max-width: 960px) {
	.quarter-progress-chart{
		left: -40px;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
	.quarter-progress-chart{
		left: -50px;
	}
}

@media only screen and (min-width: 1150px) and (max-width: 1280px) {
	.quarter-progress-chart{
		left: -50px;
	}
	.follow-up-biz-vol-num{
		font-size: 15px;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1480px) {
	.quarter-progress-chart{
		left: -65px;
	}
	.follow-up-biz-vol-num{
		font-size: 13px;
	}
}

@media only screen and (min-width: 1480px)  {
	.quarter-progress-chart{
		left: -60px;
	}
	.follow-up-biz-vol-num{
		font-size: 15px;
	}
}
//.business {
//	> .react-swipeable-view-container {
//		> div:nth-child(2) {
//			overflow-y: hidden !important;
//		}
//	}
//}