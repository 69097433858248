.preference {
    .slick-prev {
		z-index: 10;
		left: 20px;
	}

	.slick-next {
		z-index: 10;
		right: 20px;
	}

	.slick-prev:before, .slick-next:before {
		color: #3966f8 !important;
	}

	@media (max-width: 767px) {
		.slick-prev {
			left: 0px;
		}
		.slick-next {
			right: 0px;
		}
	}
	.prefBtn {
		width: 100%;
		margin-top: 35px;
		padding: 0 10px;
		text-align: center;
		position: absolute;
		bottom: 0;
		button {
			max-width: 600px;
			width: 100%;
			margin-bottom: 16px;
			color: white;
		}
	}
	.welcome-gif {
		height: 45vh;
		width: 100%;
		display: block;
		margin: auto;
	}
}

.preference-img {
	max-height: 25vh;
	margin: auto;
}

.preference-help-img {
	margin-bottom: 0px;
}

.preference-question-container:hover {
	background: #edfeff;
    cursor: pointer;
}

.preference-actions { 
	.actions-div {
		width: 100%; 
		display: flex; 
		align-items: center;
	}
	.main-actions { 
		display: flex;
		align-items: center;
	}
	.back-btn {
		flex: 2;
	}
	.dun-show-again {
		flex: 2;
		.dun-show-again-box { 
			float: unset;
			margin-top: 5px;
			padding-left: 10px; 
		}
	}
	.flex-right {
		justify-content: flex-end;
	}
}