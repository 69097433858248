@media screen and (min-width: 1024px){
    .assistant-category-wrapper{
        width: 100%;
    }
}

.assistant-category-wrapper{
    .all__icons--wrapper .centerpull .title__text {
        color: #00000080;
    }
}