.contact-modal-portal .ReactModal__Overlay {
  z-index: 2000 !important;
}

.contact-modal-portal .center-col {
  word-break: break-all;
}
.breakword {
  word-wrap: break-word;
}
