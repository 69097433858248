.copy-url-button {
  margin-left: 10px !important;
  margin-right: 10px !important;
  min-width: 50%;
}

.create-room {
  text-align: center;
  margin-top: 22px;
}

.form-title {
  margin-bottom: 12px;
}
