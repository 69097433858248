.pricing-wrapper {
	margin: 0 -16px;
	padding: 20px 16px 50px 16px;

	.no-swiper-wrapper {
		text-align: center;

		> div {
			display: inline-flex;
			margin: 0 15px;
		}
	}

	.swiper-slide {
		margin-bottom: 25px;

		.pricing-card {
			padding-bottom: 1px;
		}
	}

	.swiper-container-horizontal > .swiper-pagination-bullets {
		bottom: -5px;
	}

	.team-amount {
		font-weight: bold;
		font-size: 125%;
	}

	.actions {
		margin-top: 10px;

		button {
			z-index: 1001;
			display: block;
			margin: 10px auto;
			width: 80%;
		}
	}

	span.fab {
		position: fixed;
		bottom: 10px;
		right: 10px;
		z-index: 5;

		img {
			width: 50px;
			height: 50px;
		}
	}
}