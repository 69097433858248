.my-profile-adviser {
  .fab {
    bottom: calc(60px + var(--bottom-padding));
  }

  @media screen and (min-width: 960px) {
    .fab {
      bottom: 10px;
    }
  }
}