.promotion-content-wrapper {
    height: 100%;
    background-color: white;
    .plus-btn-message {
        button {
            color: white;
            padding: 9px 8px;
            z-index: 1400 !important;
            svg {
                font-size: 36px;
            }
        }
    }
    .message-guideline-icon {
        right: 33px !important;
        button {
            svg {
                width: 27px !important;
                height: 27px !important;
                color: #fff;
                font-size: 0px !important;
            }
        }
    }
}
.promotion-wrapper {
    > ul {
     padding: 0;
     list-style: none;
     margin-top: 80px;
     margin-bottom: 200px;
    }
       .send-Button{
           width: 45%;
       }
       .promotion-footer{
           position: fixed;
           width: 100%;
           padding-right: 30px;
           bottom: calc(var(--bottom-padding));
           padding-bottom: 7rem;
           background: #fff;
           > ul {
               padding: 0;
               font-size: 15px;
               > li {
                   padding-bottom: 5px;
               }
           }
           .promotion-status{
               .red{
                   color: red;
               }
               .green{
                   color: green;
               }
           }
           .promotion-action{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
           }
       }
       .send-swiper{
           margin-left: 10%;
       }
       .flex-li{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .MuiSwitch-root {
            margin-right: -6px;
        }
        .date-span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            .icon {
                margin-left: 5px;
                cursor: pointer;
                color: #3966f8
            }
        }
        .preview {
            font-size: 14px;
            width: 65px;
            height: 25px;
            cursor: pointer;
            line-height: 1rem;
            margin-left: 15px;
        }
       }
       @media screen and (min-width: 768px) {
               .promotion-footer{
                   width: 50% !important;
               }
       }
}
   
   .promotion-wrapper > ul > li {
    padding: 3px 0;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: normal;
   
    img {
     width: 22px;
     float: right;
     cursor: pointer;
       }
       .datePicker {
        display: none;
    }   
   }