.factsheet-edit-button{
    padding: 2px 10px !important;
    min-width: 40px !important;
    margin-left: 5px !important;
  }

.fs-value{
  border-bottom: 1px solid #fff;
  width: 75%;
  display: inline-block;
}

.fs-value-edit-icon{
  float: right;
  width: 0.8em !important;
  height: 0.8em !important;
  opacity: 0;
}

.left-value-input{
  border: 0px solid;
  border-bottom: 1px solid #d7d7d7;
  text-align: center;
  text-align-last: center;
}

.fs-value:hover{
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid #d7d7d7 !important;
  .fs-value-edit-icon{
    opacity: 0.5;
  }
}

.click-for-edit-div{
  border: 1px solid #c1afff;
  padding: 2px 6px 1px 6px;
  border-radius: 10px;
  background-color: #c1afff22;
}

.click-for-edit-div-animated{
  border: 1px solid #c1afff;
  padding: 2px 6px 1px 6px;
  border-radius: 10px;
  background-color: #c1afff22;
  -webkit-animation-name: clientctaAnimation; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: 2;
  animation-name: clientctaAnimation;
  animation-duration: 2s;
  animation-iteration-count: 2;
}

@-webkit-keyframes clientctaAnimation {
  0%   {background-color: #3EDA7D38;}
  50%  {background-color: #FFA60038;}
  100%  {background-color: #3966f838;}
}

/* Standard syntax */
@keyframes clientctaAnimation {
  0%   {background-color: #3EDA7D38;}
  50%  {background-color: #FFA60038;}
  100%  {background-color: #3966f838;}
}


  @media only screen and (min-width: 768px) {
    .div-input-wrapper{
      display: flex;
      width: 290px;
      margin: 0 auto;
      .MuiFormControl-root{
        width: 160px;
        margin: 5px 0;
      }
      .react-phone-number-input{
        width: 160px;
        margin: 5px 0;
      }
    }
    .reminder-wrapper{
      width: 315px;
      .form-group{
        margin-bottom: 0px;
        span {
          padding: 1px;
        }
      }
    }
    .button-option-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 767px) {
    .reminder-wrapper{
      .form-group{
        width: 26px;
        margin: 0 auto;
        margin-bottom: 0px;
        span {
          padding: 1px;
        }
      }
    }
    .child-save-button-wrapper{
      margin-top: 5px;
    }
  }

  .familyTag {
    display: inline-block;
    font-size: 12px;
    color: #ff570e;
    border-radius: 15px;
    padding: 1px 7px 0px 7px;
    margin: 2px 3px 3px 3px;
    box-shadow: 2px 2px 6px #bbbbbb;
    cursor: pointer;
  }