.teamContainer {
	text-align: center;
	padding: 16px;
}

.teamSelect {
	margin-left: 8px;
	width: 50% !important;
}

.titleButtonsContainer {
	display: flex;
	align-items: center;
	margin-left: 8px;
}

.addButton {
	// REVIEW: Override theme
	margin-left: 10px !important;
	min-width: 25px !important;
	width: 25px !important;
	height: 25px !important;
	border-radius: 12.5px !important;
	color: white !important;
}

.addIcon {
	width: 20px !important;
}
