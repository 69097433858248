.policy-review.custom-scroll {
  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c6cbcf;
  }
}

.policy-review {
  font-size: 13px;

  img {
    vertical-align: middle;
  }

  p {
    font-size: 100%;
    line-height: 1;
  }

  .disclaimer {
    font-size: 85%;
    line-height: normal;
    opacity: 0.9;
  }

  .heading {
    margin: 16px 0;
    line-height: 1;
  }

  .remark {
    padding-bottom: 16px;

    .remark-icon-text {
      div:first-child {
        position: relative;
        width: 20px;
        height: 20px;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  .tables {
    margin-bottom: 1px;
    > div:first-child {
      padding-top: 0;
    }

    > div {
      max-width: 100%;
      width: max-content;
      padding: 24px 0;
    }
  }

  tr {
    page-break-inside: avoid !important;
  }

  thead, tbody {
    display: contents !important;
  }

  .policy-category-table {
    table {
      border-top: 3px solid transparent;
    }
  }

  table {
    thead {
      .main-row, .sub-row {
        height: 24px;
      }
    }

    tbody {
      tr.no-border-row {
        td {
          border-bottom: none;
        }
      }
    }

    tr.total-row {
      th, td {
        padding: 3px;

        p {
          font-weight: bold;
          font-size: 115% !important;
        }
      }
    }

    tr.total-row.protection {
      background: #fbcfd0;
    }

    tr.total-row.premium {
      background: #c8ebfa;
    }

    tr {
      height: 34px;

      td, th, th:last-child, td:last-child {
        padding: 3px;
        font-size: 100%;
        line-height: 1;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          //height: 24px;
        }
      }
    }
  }

  .not_level_icon_small {
    width: 100%;
    max-width: 20px;
    margin: 0 1px;
  }
}
