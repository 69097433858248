.policy-review-choose-client {
  > div[role="button"] {
    padding: 8px 32px 8px 14px;
    min-width: 150px;
    background: #fff;
    color: #3966f8;

    > div[role="progressbar"] {
      width: 18px !important;
      height: 18px !important;
    }

    > p {
      font-weight: 500;
    }

    //> div {
    //  font-size: initial;
    //  font-weight: bold;
    //}
  }
}