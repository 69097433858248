.show-thank-you-modal .dialog-title {
  padding: 24px 24px 10px;
}

.show-thank-you-modal .dialog-content {
  padding-bottom: 0;
  overflow-x: hidden;
}

.show-thank-you-modal .thank-you-list {
  padding: 0;
}

.show-thank-you-modal .thank-you-list li div {
  padding-left: 0;
}

.show-thank-you-modal .thank-you-list > li > div:first-child {
  padding-right: 80px;
}

.show-thank-you-modal .thank-you-list li div p {
  word-break: break-word;
}

.show-thank-you-modal .content-footer {
  margin: 10px 0;
}

.show-thank-you-modal .dialog-actions {
  margin: 0 4px 8px 4px;
}

.show-thank-you-modal .contact-button,
.show-thank-you-modal .lottie {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
}

.show-thank-you-modal .contact-button {
  right: calc(50% + 35px);
  z-index: 1;
}

.show-thank-you-modal .contact-button img {
  height: 25px !important;
}

.show-thank-you-modal .lottie {
  right: 50%;
}
