.say-thank-you-modal {
  text-align: center;
}

.say-thank-you-modal .dialog-title * {
  color: #3966f8;
}

.say-thank-you-modal .dialog-content * {
  color: #333333;
}

.say-thank-you-modal .dialog-content {
  padding: 0 24px 4px;
}

.say-thank-you-modal .dialog-actions {
  margin: 8px 4px;
}

.say-thank-you-modal .dialog-actions > div {
  width: 100%;
}

.say-thank-you-modal .dialog-actions button.yes {
  font-size: 110%;
  box-shadow: unset;
  min-width: 150px;
}

.say-thank-you-modal .dialog-actions button {
  text-transform: none;
}

.say-thank-you-modal .dialog-actions .heart-wrapper {
  height: 30px;
}

.say-thank-you-modal .dialog-actions .heart-wrapper .lottie {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.say-thank-you-modal .button-wrapper {
  width: 100%;
  text-align: center;
}

.say-thank-you-modal .dialog-actions button.no {
  min-height: 24px;
  margin-top: 6px;
  padding: 6px 2px;
}
