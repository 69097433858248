.portfolio-actions {
  padding: 4px;

  > div {
    text-align: center;
  }

  button {
    min-width: unset;
    padding: 0;
    background: #fff;
  }
}