.payment-info {
	h5, .payment-card {
		margin-top: 15px;
	}

	.payment-card {
		border-radius: 10px;

		.basic {
			height: 100px;

			> div {
				height: 100%;
			}

			> div:nth-child(2) {
				padding: 10px 6px;
				justify-content: flex-start;
				background-color: #3966f8;
				color: #ffffff;

				p {
					font-weight: 500;
					word-break: break-word;
				}
			}
		}
	}

	.center-wrapper {
		padding: 10px 16px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.qr-code-wrapper {
		img {
			max-width: 200px;
		}
	}

	button.action {
		margin: 15px 0 0 0 !important;
	}
}