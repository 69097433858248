.adviser-info {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  >div {
    height: 100%;
    width: 100%;
  }

  >div.invisible {
    display: none;
  }

  .adviser-info-left {
    vertical-align: middle;
    position: relative;
    padding: 10px 5px 10px 15px;
  }

  .adviser-info-left-qr {
    vertical-align: top;
    padding: 10px 5px 10px 15px;
  }

  .adviser-info-name {
    display: block;
    line-height: 1;
  }

  .adviser-info-header {
    font-size: 16px;
    color: #757575;
  }

  .adviser-info-phone .link,
  .adviser-info-email,
  .adviser-info-whatsapp,
  .adviser-info-wechat,
  .adviser-info-line {
    text-decoration: underline;
  }

  .adviser-info-phone>span {
    font-size: 20px;
  }

  .adviser-info-contact>div {
    height: auto;
    border: 0;
  }

  .adviser-info-contact>div>div {
    width: 22px;
    height: 22px;
    background: none !important;
    padding: 1px;
  }

  .adviser-info-contact>div>span,
  .adviser-info-contact>div a {
    font-size: 11px;
    overflow: inherit;
    color: #757575;
  }

  .adviser-info-contact .MuiChip-avatar,
  .adviser-info-contact .MuiChip-avatar img {
    height: 22px;
    width: 22px;
    margin-left: 0 !important;
    padding: 1px;
  }

  .adviser-info-wechat-line {
    min-height: 27px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .adviser-info-right {
    vertical-align: middle;
    padding: 5px;

    >div {
      >div {
        background-color: white;
        color: #3966f8;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        font-size: 50px;
        font-weight: bold;
        border-radius: 50%;
      }
    }
  }

  .adviser-info-qr-right {
    .emergency_reference--qrcode {
      width: 100%;
      line-height: 0;

      >img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }

  .advisor-right-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .advisor-right-avatar-wrapper {
    position: relative;
    width: 80%;
  }

  .advisor-right-avatar-wrapper:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  .advisor-right-avatar-wrapper>div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    color: #03CEA4;
    font-size: 55px;
    font-weight: 700;
  }

  .advisor-right-avatar-wrapper>div>span {
    transform: translate(2px, 2px);
  }

  .advisor-right-avatar-wrapper.female>div {
    color: #EE82EE;
  }

  .adviser-info-right>svg {
    display: block;
    margin: auto auto auto auto;
    transform: scale(3);
  }

  .adviser-info-right>canvas {
    width: 140%;
    vertical-align: middle;
  }

  .adviser-info-right img {
    width: 80px;
  }
}