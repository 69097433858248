.dashboard-target-modal {
	.action {
		display: unset;
		min-width: 275px;

		.button-wrapper {
			margin-left: 0;
			padding-top: 0;
			button {
				width: 100%;
				font-size: inherit;
			}
		}
	}
}