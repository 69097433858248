$content-min-height: 30vh;

button.draftJsEmojiPlugin__button__qi1gf {
	padding: 5px 0;
}

.my-editor:not(.read-only) .DraftEditor-root {
	border-radius: 0 0 5px 5px;
	min-height: $content-min-height;
	padding: 4px;
	//max-height: 60vh;
	box-shadow: 0 1px 3px 0 rgba(220,220,220,1);
	margin-top: 0;
	//height: 100%;

	.public-DraftEditor-content {
		min-height: $content-min-height;
	}
}

.my-editor.read-only .DraftEditor-root {
	pointer-events: none;
}

.my-editor-html p, .my-editor .public-DraftStyleDefault-block {
	margin: 0;
}

.my-editor-html ol, .my-editor-html ul, .my-editor .public-DraftStyleDefault-ol, .my-editor .public-DraftStyleDefault-ul {
	margin: 8px 0;
	padding: 0;
}

.my-editor {
	position: relative;
	//height: 100%;

	.DraftEditor-alignCenter {
		text-align: center;
	}

	.DraftEditor-root {
		background-color: #fff;
		//overflow-y: auto;
	}

	.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
		margin-left: 2em;
	}

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
		left: -2em;
	}
	
	.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
		margin: 8px 0;
		padding: 0;
	}

	img {
		max-width: 100%;
	}

	.align-center > div {
		text-align: center;
	}

	.align-left > div {
		text-align: left;
	}

	.align-right > div {
		text-align: right;
	}
}
