.subscription-content {

  .container {
    // background-color: #dddddd;
    height: calc(100vh - 45px - 50px);
    display: flex;
    flex-direction: column;
  }

  .expand {
    flex: 1
  }

  .current-subscriptions {
    align-items: center;
    text-align: center;
    padding-bottom: 10px;
    margin-top: 50px;

    .subscription-plan-card,
    >button,
    >div {
      display: block;
    }

    .subscription-plan-card {
      position: relative;

      button.cancel {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        color: #fff;
      }
    }
  }

  .new-sign-up {
    .stepper {
      padding: 12px 0;
    }
  }
}