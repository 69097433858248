.account-deletion {

    /* padding: 24px; */

}

.account-deletion.wrapper {
    /* background-color: #f5f5f5; */
    justify-content: center;
    width: 100%;
    display: flex;
}


.account-deletion .fixed-wrapper {
    /* background-color: #f5f5f5; */

    justify-content: center;
    text-align: center;
    width: 100%;
}

.account-deletion .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.account-deletion .icon {
    font-size: 5rem;
    color: green;
}

.account-deletion .title {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.account-deletion .description {
    font-size: 1.5rem;
    margin-bottom: 20px;
}


.account-deletion.dim {
    color: #999 !important;
}