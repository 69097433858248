.policy-category-table-wrapper {
  border: 2px solid #3966f8;
  padding: 8px;
  position: relative;

  .first-table, .second-table {
    width: 100%;
  }

  .policy-review-card {
    div.without-edit {
      overflow-x: auto;
    }
  }

  .policy-review-card.partial-scroll {
    div.without-edit {
      .scrollable-region {
        overflow-x: auto;
      }
    }
  }
}