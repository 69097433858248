.editIconButton {
  padding: 0px !important;
  padding-bottom: 8px !important;
}

.calculator-profiles {
  .swiper-container {
    padding-bottom: 24px;

    > .swiper-pagination {
      bottom: -4px
    }
  }
}
