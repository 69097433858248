.create-demo-policy-table table {
    width: 100% !important;
}

.create-demo-client-container {
    .MuiSvgIcon-root {
        width: 0.8em;
        margin-right: 5px;
    }
    .MuiGrid-spacing-xs-1 {
        width: calc(100% + 18px);
    }
    .MuiTableCell-sizeSmall {
        padding: 0 !important;
        height: 45px;
    }
    .MuiIconButton-root {
        padding: 0;
    }
    .demo-client-name input {
        height: 1.6em;
        padding-bottom: 1px;
    }
    .demo-client-name.input-padding-bottom input {
        padding-bottom: 3px;
    }
}