.nsu {
	min-height: 100vh;
	background: #fff;

	h4 {
		font-weight: bold;
		//margin: 16px 0;
	}

	.button-options.fw {
		min-width: 110px;
	}

	.button-options {
		button {
			font-size: 14px;
			padding: 2px 10px;
			margin-left: 6px;
			margin-right: 6px;
			min-width: 75px;
			line-height: 1.6;
		}

		button.darkblue {
			background-color: #252091 !important;
		}

		button:not([disabled]):not(.selected) {
			background: #ffffff !important;
			color: #3966f8 !important;
		}
	}

	.input {
		margin-top: 8px;
		margin-bottom: 7px !important;
		background: white;

		> div {
			font-size: inherit;
		}

		//fieldset {
		//	border: 2px solid #3966f8;
		//	border-radius: 12px;
		//
		//	legend {
		//		font-size: 135%;
		//	}
		//}

		//> div:not(.Mui-error):hover fieldset, > div.Mui-focused:not(.Mui-error) fieldset {
		//	border-color: #3966f887 !important;
		//}

		input {
			//font-weight: 400;
			height: 15px;
		}

		//.react-phone-number-input, input:not([name="phoneNumber"]) {
		//	padding: 13px 14px;
		//}

		//label {
		//	transform: translate(14px, 15px) scale(1);
		//	font-weight: bold;
		//	opacity: 0.6;
		//}
		//
		//label[data-shrink="true"], label.non-shrink {
		//	transform: translate(14px, -5px) scale(0.75);
		//}

		> div:before, > div:after {
			display: none;
		}

		p {
			margin: 0;
			padding-left: 6px;
			font-size: 1.25rem;
		}
	}

	button.action {
	/*	border-radius: 10px; */
		margin: 8px 0;
		padding: 8px;
		box-shadow: none;
	}

	.type-a {
		border-radius: 8px !important;
		padding: 8px;
		min-height: 48px;
		font-size: 125%;
	}
}