.standard-content-item {
	strong, b {
		color: #3966f8;
		font-weight: unset;
	}

	.item-title {
		font-size: 110%;
	}

	.item-details {
		display: block;
		padding: 0 15px 15px;

		.texts-wrapper {
			padding: 10px 0 0 0;
			word-break: break-all;

			.text-wrapper {
				padding-bottom: 8px;

				.text-title {
					font-weight: 600;
				}
			}
            .text-copy {
              padding: 10px 0;

              .text-copy-item {
				font-weight: 700;
				font-size: 95%;
              }
            }
		}

		@media (min-width: 600px) {
			.texts-wrapper, .buttons-wrapper {
				padding: 0 0 0 10px;
			}
		}

		.video-wrapper {
			width: 100%;
			padding-top: 56.25%;
			position: relative;

			.react-player {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}

		.buttons-wrapper {
			padding-bottom: 10px;
		}
	}
}

.standard-content-item.no-expansion {
	@media (min-width: 600px) {
		.item-title {
			margin-bottom: 10px;
		}
	}

	.item-video {
		margin-bottom: 10px;
	}

	.item-details {
		padding: 0;
	}
}