.share-wif-adv-in {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  width: 100%;

  button {
    margin-bottom: 15px;
  }
}