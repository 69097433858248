.qr-share-confirmation-card {
  padding: 12px;
}

.qr-share-info-line {
  padding: 0 8px;
}

.qr-share-info-label,
.qr-share-info-value {
  display: inline-block;
}

.qr-share-info-label {
  width: 20%;
  text-align: right;
}

.qr-share-info-value {
  width: 80%;
  text-align: left;
  padding-left: 10px;
}

button.qr-share-button {
  width: 30%;
  font-size: 110%;
  text-transform: unset;
  margin: auto;
}
