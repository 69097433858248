.referral-card-wrapper {
	.logo-wrapper {
		margin-bottom: 10px;
		text-align: center;

		> div {
			max-width: 500px;
			margin: 0 auto;
			position: relative;
			border-radius: 8px;
			overflow: hidden;

			img {
				max-width: 280px;
				width: 100%;
			}
		}
	}

	.card-wrapper {
		max-width: 500px;
		margin: 0 auto;
		padding-top: 5px;
		position: relative;

		.icon-wrapper, .referral-card {
			box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.22);
		}

		.icon-wrapper {
			position: absolute;
			top: -44px;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 1;
			border-radius: 50%;

			img {
				width: 68px;
			}
		}

		.referral-card {
			font-size: 150%;
			border-radius: 10px;
			background: rgb(238, 240, 252);
			.card-content {
				padding: 30px 10px 10px;

				.referral-message-wrapper {
					margin: 0 auto;
					display: block;

					.referral-message {
						display: flex;
						justify-content: center;
						flex-direction: column;
						height: 100%;

						> div {
							color: #3966f8;
							//CNY theme : color: #d70007;
						}

						> div::before {
							display: none;
						}

						textarea {
							text-align: center;
							line-height: 1.25;
							overflow: hidden;

							::placeholder {
								opacity: 1 !important;
							}
						}

						textarea::placeholder {
							opacity: 1 !important;
						}
					}
				}

				.qr-code-container {
					width: 80%;
					margin: 5px auto 0;
					border-radius: 8px;

					.qr-code-refer {
						width: 100%;

						#qr-code {
							height: auto !important;
							width: 100% !important;
							max-height: unset !important;
							max-width: unset !important;
							border-radius: 10px;
						}
					}
				}

				.button-wrapper {
					margin: 10px 0;

					div {
						background: #3966f8;
						color: #ffffff;
						border-radius: 10px;
						padding: 4px 8px;
						width: 80%;
						margin: 0 auto;
						text-align: center;
					}
				}
			}
		}
	}
}