.personal-details-form {
  .add-client-reminder-date-add-todo-icon-btn {
    @media screen and (min-width: 601px) {
      display: none !important;
    }
  }
  .add-client-reminder-date-add-todo-btn {
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .fast-made-pdf-btn{
    padding: 1px 4px 0px 0px !important;
  }

  .fast-made-icon{
    margin-bottom: -3px;
    padding: 1px 0 1px 0px;
  }

  .aladdin-hint {
    > div {
      flex-wrap: nowrap;

      p {
        white-space: pre;
      }
    }
  }

  .familyTag {
    display: inline-block;
    font-size: 12px;
    color: #ff570e;
    border-radius: 15px;
    padding: 1px 7px 0px 7px;
    margin: 2px 3px 3px 3px;
    box-shadow: 2px 2px 6px #bbbbbb;
    cursor: pointer;
  }
}