.email-verify-modal {
	.content {
		padding-bottom: 20px;
		text-align: center;
		white-space: pre-wrap;

		> *:not(:last-child) {
			margin-bottom: 15px;
		}

		> :nth-child(2) {
			margin-bottom: 8px;
		}
	}
}