.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(45px + var(--top-padding));
  padding-top: var(--top-padding);
  color: #fff;
  background-color: #3966f8;
  box-shadow: 0px 3px 5px 0 rgba(109, 109, 109, 0.16);
  z-index: 1043;
  list-style: none;
  padding-inline-start: unset;

  .title {
    position: absolute;
    left: 50px;
    right: 40px;
    bottom: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1px;
    margin: 0;
    height: 100%;

    > div {
      position: relative;
      height: 100%;
      width: 100%;

      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }

  .Bell {
    position: absolute;
    top: 6px;
    right: 15px;
  }

  .button {
    width: 50px;
    height: 30px;
    margin: 0 8px !important;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .button:hover, .button:active {
    outline: none;
  }

  .button a {
    display: block;
    text-align: center;
    font-size: 18px;
    color: #fff;
    height: 50px;
    padding-top: 14px;
    cursor: pointer;
  }

  .button a:hover, .button a:active, .button a:focus {
    text-decoration: none;
    outline: none;
    background: transparent;
  }

  .button a:active {
    background-color: rgba(121, 174, 254, 0.90);
  }

  .back_arrow{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1043;
    left: 0;
    color: white !important;
    cursor: pointer;
    padding: 0 6px;
  }
  
  .back_arrow svg {
    font-size: 36px !important;
    vertical-align: middle;
  }
}