div.blocking-overlay ~ *, div.blocking-overlay ~ a > div {
	filter: blur(1.5px);
}

div.blocking-overlay.strong ~ *, div.blocking-overlay.strong ~ a > div {
	filter: blur(5px);
}

div.blocking-overlay {
	font-size: initial;
	position: absolute;
	z-index: 1000;
	border-radius: inherit;
	width: 100%;
	height: 100%;
	max-width: unset !important;
	max-height: unset !important;
	padding: unset !important;
	margin: unset !important;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.25) !important;

	* {
		max-width: unset !important;
		max-height: unset !important;
		padding: unset !important;
		margin: unset !important;
	}

	.position-wrapper {
		height: 100%;
		width: 100%;
		background: transparent !important;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: inherit;

		.content-wrapper {
			width: 100%;
			font-weight: bold;
			font-size: 115%;
			box-shadow: 0 0 30px rgba(16, 14, 23, 0.25);
			border-radius: 8px;
			position: relative;
		}

		.content {
			padding: 20px !important;
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #FFCC00;
			color: #000000;
			opacity: 0.35;
		}
	}
}