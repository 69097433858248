.nav-drawer::-webkit-scrollbar {
    display: none;
}

.language-btn {
    cursor: pointer !important;
}

.language-btn:hover {
    background-color: transparent;
}

.language-btn span {
    margin: 0 !important;
}