.hot-topic-item-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 110%;
  padding-top: 8px;
  color:#000000de;
}

.hot-topic-img {
  box-shadow: 3px 3px 8px 2px #3966f828;
  border-radius: 8px;
}

.hot-topic-img:hover{
  box-shadow: 3px 6px 16px 5px #3966f828;
  border-radius: 8px;
  transform: translateY(-2px);
}
.hot-topic-text-title {
  color: #3966f8;
  font-weight: 400;
}

.hot-topic {
  .slick-track {
    margin-left: 0;
  }
  .slick-prev:before {
    color: #3966f8 !important;
    font-size: 27px;
  }
  .slick-next:before {
    color: #3966f8 !important;
    font-size: 27px;
  }
  .slick-prev {
    top: 75px;
  }
  .slick-next {
    top: 75px;
  }
  @media screen and (min-width: 850px) {
    .slick-prev {
      top: 90px;
    }
    .slick-next {
      top: 90px;
    }
  }
}

.hot-topic-item-dialog {
  .item-details {
    max-width: 100%;
    .MuiGrid-direction-xs-column > div {
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    padding: 24px 24px 10px;
  }
  .text-copy section {
    margin-bottom: 0 !important;
  }
  .standard-content-item {
    padding: 0;
  }
}

.hot-topic-item-button {
  width: 100px;
  margin: 0 5px !important;
  @media screen and (min-width: 600px) {
    width: 120px;
  }
  @media screen and (min-width: 850px) {
    margin: 0 35px !important;
  }
  img {
    width: 100px;
    height: 150px;
    margin: auto;
    object-fit: contain;
    border-radius: 8px;
    @media screen and (min-width: 600px) {
      width: 120px;
      height: 180px;
    }
  }
}

.hot-topic-category-title {
  font-size: 18px;
  color: #3966F8;
  padding: 6px;
}

.hot-topic-row-mobile::-webkit-scrollbar {
  display: none;
}
