.partnership-details {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    width: 100%;
    height: 100vh;
    padding: 50px 15px 100px 15px;

    @media screen and (min-width: 960px) {
        padding: 50px 15px 65px 15px;
        font-size: 15px;
    }

    background-color: white;
    white-space: pre-line;

    .partnership-content {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        text-align: left;

        .partnership-main-img {
            text-align: center;
            margin: auto;

            img {
                width: 100%;

                @media screen and (min-width: 700px) {
                    height: 100%;
                    max-height: 40%;
                }
            }
        }

        .partnership-title {
            margin-top: 10px;
            color: #3966f8;

        }

        .partnership-main-text {
            color: #333333;
            margin-top: 10px;

            span {
                font-weight: normal;
            }
        }

        .partnership-terms {
            color: #0000008a;
            margin: auto;
            text-align: start;
            width: 100%;
        }
    }

    .more-details {
        position: fixed;
        bottom: 76px;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(100% - 20px);
        text-align: center;

        button {
            width: 100%;
            max-width: 552px;

            a {
                color: white;
            }
        }
    }

    @media screen and (min-width: 960px) {
        .more-details {
            padding-left: 56px;
            bottom: 10px;
        }
    }



    p.holdcover {
        font-weight: normal;
    }


    .holdcover .highlight {
        color: #4285f4;
    }

    .holdcover .faq .title {
        font-size: 24px;
        font-weight: 500;
        color: #777
    }

    .holdcover .faq .question {
        font-weight: bold;
    }

    .holdcover .faq .answer {
        color: #999
    }

    li.affiliate {
        list-style-type: disc;
    }

    li.affiliate::marker {
        color: black;
    }
}

.event {
    span.bold {
        font-weight: 700;
    }

    span.blue {
        color: blue;
    }

}