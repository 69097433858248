.policy-category-table.premium:not(.no-policy) {
  table {
    border-bottom: none !important;
  }
}

.policy-category-table.premium {
  table {
    border: 3px solid #c8ebfa !important;

    tr.sub-header {
      th {
        position: relative;
        border-bottom: none;
      }
      th::after {
        content: '';
        position: absolute;
        bottom: -1.5px;
        left: 0;
        width: 100%;
        border-bottom: 3px solid #3966f8;
      }
    }
  }
}