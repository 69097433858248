.pfa-graph-card {
	.highlight-details {
		color: #454F63;
		font-weight: 600;

		.value-wrapper {
			font-weight: bold;

			.value {
				font-size: 250%;
			}

			.currency {
				font-size: 125%;
				margin-left: 6px;
			}
		}
	}

	.highlight-details.left .value {
		color: #f89239;
	}

	.highlight-details.right .value {
		color: #3966f8;
	}
}
.pf-graph-card-container {
	.MuiGrid-container {
		width: 100%;
		margin: 0;
	}
	.MuiGrid-spacing-xs-8 > .MuiGrid-item {
		padding: 0 4px;
	}
}