.new-help-center {
  .hot-topic-item-button {
    width: 150px !important;

    img {
      height: 85px !important;
      width: 150px !important;
    }
  }
}
