$original-width: 510px;
$collapsed-width: 280px;
$duration: 500ms;

.policy-category-table.info {
  table {
    width: $original-width;

    thead {
      tr.sub-header {
        th {
          position: relative;

          .category-title {
            padding: 8px;
            color: white;
            background: var(--new-theme);
            border-radius: 4px;
            width: 85px;
            margin: 0 auto;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 110%;
          }
        }
      }
    }

    td {
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .collapse-enter, .collapse-exit-done {
    width: $original-width;
  }
  .collapse-enter-active {
    width: $collapsed-width;
    transition: width ease $duration;
  }
  .collapse-exit, .collapse-enter-done {
    width: $collapsed-width;
  }
  .collapse-exit-active {
    width: $original-width;
    transition: width ease $duration;
  }
}