.nav-tabs,
.nav-tabs .tab-item {
  min-height: 38px !important;
}

.nav-tabs .tab-item {
  text-transform: unset;
  max-width: unset;
  min-width: 72px !important;
}

.nav-tabs .tab-item span {
  font-size: 15px;
  font-weight: 600;
  color: #757575;
  padding: 0;
}

.nav-tabs .tab-item[aria-selected='true'] span {
  color: #3966f8;
}

.set-color {
  color: #3966f8 !important;
}
.set-opacity {
  opacity: 1 !important;
}
