.total-amount-card-wrapper {
	div.total-amount-card {
		background: #3966f8;
		border-radius: 12px;
		padding: 16px 0;

		.card-header {
			padding: 0 0 4px 0;

			div > span > div {
				display: inline-flex;
				background: white;
				color: cornflowerblue;
				font-size: 125%;
				line-height: normal;
				padding: 2px 28px 2px 12px;
				border-radius: 0 18px 18px 0;
			}
		}

		.card-content {
			color: white;
			font-weight: bold;
			font-size: 80px;
			line-height: 1;
			text-align: center;
			padding: 4px 4px;

			sup {
				font-size: 50%;
				top: -.6em;
			}
		}
	}

	p {
		opacity: 0.5;
		margin-top: 5px;
	}
}