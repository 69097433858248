.note-icon-button,
.target-icon-button {
  position: absolute !important;
  top: 0;
  right: 0;
}

.note-icon-button img,
.target-icon-button img {
  width: 80%;
}

.business-target-dialog .adv_biz_modal_key input {
  padding: 0;
  line-height: 1;
}
.card-button > button {
  padding: 0;
  width: 48px;
  height: 48px;
}

.adv_biz_modal_key input {
  padding: 0;
  line-height: 1;
}
