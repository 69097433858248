.covid-qc {
	background: url("/img/BG_Chopped.png") no-repeat;
	background-position-y: 50px;

	.my-container {
		height: calc(100vh - 95px);
		text-align: center;

		p, h1 {
			white-space: pre-wrap;
		}

		.address-input {
			margin-bottom: 15px;
			width: 100%;
			background: #fff;
			border-radius: 4px;
			padding: 4px 16px;
			height: 48px;
			font-size: 105%;
			box-shadow: 0 3px 5px 0 rgba(109, 109, 109, 0.16);
		}

		button {
			height: 48px;
		}
	}
}