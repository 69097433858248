button.image-icon-button {
  box-shadow: 3px 3px 15px 2px #3966f828;
  background-color: white;
  padding: 15px 0 10px 0;
  border-radius: 10px;

  > span {
    border-radius: inherit;
  }

  img {
    height: 60px;
    object-fit: contain;
    max-width: 100%;
  }

  p {
    color: #616161;
  }
}

button.image-icon-button:hover{
  box-shadow: 3px 6px 30px 5px #3966f828;
  background-color: white;
  transform: translateY(-2px);
}
