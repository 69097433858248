.portfolio-category {
  .info-boxes {
    .info-box {
      color: #3966f8;

      .title {
        svg {
          vertical-align: sub;
          font-size: 20px;
        }
        p {
          font-size: 100%;
        }
      }

      .content {
        text-align: center;

        .value {
          font-size: 220%;
          line-height: 0.9;
          font-weight: 500;
        }
      }
    }
  }

  .portfolio-selector-wrapper {
    height: 100%;
  }

  .portfolio-selector-wrapper, .portfolio-chart-wrapper, .portfolio-subcategory, .dash-Life-CI {
    border-radius: 5px !important;
    box-shadow: 0 3px 5px 0 rgba(109, 109, 109, 0.27) !important;
  }

  .portfolio-subcategory {
    .details {
      > div {
        :last-child {
          .policy-entry {
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }
  }
}

.dashboard-wrapper .portfolio-subcategory:last-child {
  margin-bottom: 50px !important;
}