.welcome-dialog {
	.wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;

		.welcome-dialog-header {
			flex: 1;
			min-height: 64px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.content {
			> :first-child {
				margin: 15px 0;
			}

			> :first-child, > :nth-child(2) {
				opacity: 0.8;
			}

			> :first-child, > :last-child {
				font-weight: bold;
			}
		}
	}

	.button-wrapper {
		position: relative;
		justify-content: left;
		padding: 8px;
		display: block;

		label {
			display: block;
			span {
				font-size: 75%;
			}
		}

		button {
			margin: 0;
			box-shadow: none;
		}

		div {
			position: absolute;
			bottom: 10px;
			right: 24px;

			p {
				text-decoration: underline;
			}
		}
	}
}
