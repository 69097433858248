.pikto-adviser > div > div {
  width: 100%;
}

.pikto-adviser .adviser-info > div {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.pikto-adviser .adviser-info > div .adviser-info-left {
  padding-left: 10px;
}

.pikto-adviser .adviser-info > div .adviser-info-qr-right > div > div {
  height: 100%;
}

.pikto-adviser .whiteWrapper {
  padding-bottom: 10px;
}

.pikto-adviser .whiteWrapper #loginWrapper {
  min-height: unset;
}

.pikto-adviser .powered-by p {
  font-size: 13px;
}

.pikto-adviser .svg-symbol {
  z-index: 0;
}

.pikto-adviser .sticky-outer-wrapper.active .sticky-inner-wrapper > div {
  padding-top: calc(var(--top-padding) + 8px) !important;
  margin-top: calc(var(--top-padding) * -1) !important;
}
