.contentSlidesRow {
  // explicit requirement: 24px bottom margin
  margin: 16px 20px 24px;

  .slick-track {
    margin-left: 0;
  }

	.slick-prev:before {
    color: #3999f8 !important;
    font-size: 27px;
  }
  .slick-next:before {
    color: #3999f8 !important;
    font-size: 27px;
  }
}

.contentSlidesHeader {
	display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 16px;
}

.contentSlidesHeaderTitle {
	display: flex;
	align-items: center;
}

.mobileSliderContainer {
	display: flex;
	flex-direction: row;
	overflow: scroll;
}

.mobileSliderContainer::-webkit-scrollbar {
  display: none;
}

.contentSlidesSeeMore {
	color: #9E9E9E !important;
}
