.template {
    max-width: 300px;
    min-width: 300px;
    height: 100%;
    overflow: hidden;
    padding-bottom: 100px;
    .full-width {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: bolder;
    }
    
    .list-container {
        padding: 0 0 100px 0;
        overflow: auto;
        max-height: 100%;
    }
    
    .active-template {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        padding-left: 10px;
        border-left: 5px solid #3966f8;
        font-weight: bolder;
        border-bottom: 1px solid #D3D3D3;
        div {
            display: flex;
            align-items: center;
            .important{
                padding-left: 5px;
            }
            svg {
                fill: #fc0;
            }
        }
    }
    
    .other-template {
        border-bottom: 5px solid rgba(0, 0, 0, 0.15);
    }
    
    svg.add-btn {
        width: 33px;
        height: 33px;
        fill: #3966f8;
        margin-right: 11px;
    }
    
    .top-bar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D3D3D3;
        height: 45px;
        padding: 10px 0px 10px 10px;
        .title {
            font-weight: bolder;
        }
    }
    
    .expandMore {
        animation: rotate-keyframes-down 0.5s;
        animation-fill-mode: forwards;
    }
    
    @keyframes rotate-keyframes-down {
        from {
            transform: rotate(-180deg);
        }
       
        to {
         transform: rotate(0deg);
        }
       }
    
    .expandLess {
        animation: rotate-keyframes-up 0.5s;
        animation-fill-mode: forwards;
       }
    
       @keyframes rotate-keyframes-up {
        from {
        }
       
        to {
         transform: rotate(-180deg);
        }
       }
    
    .notepad {
        position: absolute !important;
        right: 36px;
        width: 48px;
        height: 48px;
        img {
            width: 100%;
        }
    }
    
    .add-row {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #D3D3D3;
        svg.template-help {
            fill: #3966f8;
        }
    }
}