.dashboard {
    .dashboard-drawer-graph-container {
        margin-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .dashboard-drawer {
        padding-bottom: 0 !important;
    }
}

.dashboard-drawer-wrapper {
    @media screen and (min-width: 960px){
        width: calc(100% - 400px) !important;
    }
}

.dashboard-drawer-nav-wrapper {
    @media screen and (min-width: 960px){
        width: calc(100% - 460px) !important;
    }
}