.editor-container {
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid #D3D3D3;
    > .date-picker {
        display: none;
    }
}

.save-and-cancel {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-top: 0;
    align-items: center;
    height: 45px;
    button {
        color: #3966f8;
    }
}

.no-delete {
    justify-content: flex-end !important;
}

svg.checked, svg.unchecked { 
    fill: #fc0;
}

.star > .star-field {
    display: flex;
    width: 46%;
    border-radius: 12px;
    align-items: center;
    margin-left: 6px;
    margin-top: 5px;
    svg {
        margin: 12px 12px 12px 6px;
    }
    .star-text {
        font-size: small;
        min-height: 21px;
        width: 60%;
        padding-top: 3px;
    }
}

.star-field:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}