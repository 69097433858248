.cheque-out {
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.cheque-out .nav-top-margin {
  margin-top: 60px;
}
