#accordion .connection-status .zodiacIconLabel {
    background: #3966f8;
    padding: 0px 2px 0px 2px;
    color: white;
    border-radius: 5px;
}

.img__marg--right {
    margin-top: 8px !important;
    float: right;
}

.track__pad--btm {
    padding-bottom: 0px !important;

    .clientName {
        margin-top: -7px;
    }
}

.portfolio-default-button.disc-btn {
    min-height: 22px !important;
    min-width: 22px !important;
    padding: 2px !important;
    margin-left: 0 !important;

}

.portfolio-default-button.client-disc-btn {
    height: 22px !important;
    width: 22px !important;
    margin-left: 4px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    padding: 1px 0 !important;
    text-align: center;

}

button.prevNextButton {
    min-width: 100px;
}

button.submitButton {
    min-width: 130px;
}

.track_biz {
    .MuiSwitch-root {
        width: 48px;
        height: 39px;
        margin-left: -12px;
        padding-right: 0;
    }

    .MuiSwitchBase-root-1556 {
        width: 48px;
    }

    .MuiSwitch-track {
        width: 30px !important;
        margin-left: 0;
    }

    .marg__toggle--lr {
        margin-left: -8px;

        .MuiSwitch-root {
            width: 50px;
            height: 39px;
        }

        .MuiSwitchBase-root-1556 {
            width: 48px;
        }

        .MuiSwitch-track {
            width: 22px !important;
            margin-left: 3px;
        }
    }
}

.client {
    .plus-icon-client {
        button {
            padding: 4px 10px;

        }
    }

    .page-body {
        .panel {
            border-top: 0;
            border-bottom: 0;

            .panel-heading {
                border-color: unset;
                background-color: unset;
                color: unset;
            }
        }

        .client-page-desktop-wrapper {
            ::-webkit-scrollbar {
                display: none;
            }

            .client-list {
                padding-bottom: 80px;

                .fab {
                    position: fixed;
                    bottom: 100px;
                    right: 20px;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .fab1 {
                    bottom: 178px;
                }

                .fab2 {
                    bottom: 256px;
                }

                .fab3 {
                    bottom: 334px;
                }
            }
        }

        .fabText {
            background-color: #EFF3FD;
            color: black;
            font-size: 16px;
            margin-right: 10px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            padding: 6px 12px;
        }

        @media (min-width: 801px) {
            .client-page-desktop-wrapper .client-list .fab {
                right: 270px;
            }
        }

        @media (min-width: 1180px) {
            .client-page-desktop-wrapper .client-list .fab {
                right: calc((100vw - 50px - 1180px) / 2 + 270px);
            }
        }

        @media (min-width: 800px) {
            .client-page-desktop-wrapper {
                display: flex;

                // margin-top: -5px;
                .user-view-wrapper {
                    flex-grow: 2;
                    overflow: scroll;
                }

                .client-list {
                    width: calc(100% - 300px);
                    flex-grow: 2;

                    .fab {
                        bottom: 100px;
                    }

                    .fab1 {
                        bottom: 178px;
                    }

                    .fab2 {
                        bottom: 256px;
                    }

                    .fab3 {
                        bottom: 334px;
                    }

                    margin-right: 6px;
                }

                .search-filter-wrapper {
                    width: 245px;
                }
            }

            .pagination-wrapper {
                width: 100%;
                padding-right: 245px;
            }
        }

        @media (min-width: 960px) {
            .client-page-desktop-wrapper {
                .client-list {
                    .fab {
                        bottom: calc(55px + var(--bottom-padding));
                    }

                    .fab1 {
                        bottom: calc(133px + var(--bottom-padding));
                    }

                    .fab2 {
                        bottom: calc(211px + var(--bottom-padding));
                    }

                    .fab3 {
                        bottom: calc(289px + var(--bottom-padding));
                    }
                }
            }
        }
    }

    .pagination-wrapper {
        width: 100%;
        z-index: 1;
        background: #fff;

        >* {
            width: fit-content;
            display: inline-block;
        }

        >div>button {
            margin: 2px;
            min-height: unset;
            padding: 0 4px 0 4px;
            border: 1.1px solid #3966f8;
        }

        .my-table-pagination {
            float: right;
            margin-bottom: -2px;

            .MuiTableCell-root {
                border-bottom: 0;
                overflow: hidden;
            }
        }

        .my-table-pagination tr td>div {
            padding-left: 0;
        }

        .pagination-actions {
            margin-top: 2px;
        }

        .MuiInput-underline {
            &::before {
                content: '';
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            }
        }
    }

    @media (min-width: 960px) {
        .page-body {
            .pagination-wrapper {
                width: 100%;
                padding-right: 300px;
                bottom: 0;
            }
        }
    }

    .reset-wrapper {
        width: 24px;
        height: 100%;
        display: inline-flex;

        button {
            width: 100%;
            height: 100%;
            //top: -3px;
        }
    }
}

button.notification-btn {
    right: 62px !important;
    bottom: 2px !important;
    padding: 8px;
    color: white;

    @media (min-width: 600px) {
        right: 96px !important;
    }
}

.client-guideline-icon {
    right: 33px !important;

    button {
        padding: 9px 5px !important;

        svg {
            width: 27px !important;
            height: 27px !important;
            color: #fff;
            font-size: 0px !important;
        }
    }

    @media (min-width: 600px) {
        right: 49px !important;

        button {
            padding: 9px 8px !important;
        }
    }
}

.client-message-icon {
    width: 28px;
    height: 32px;
    padding-bottom: 6px
}


.virus-instruction {
    text-decoration: underline;
    color: #3966f8;
}

// .client-plus-icon{
//     top:-4px !important;
// }
.cpi-btn {
    .MuiButton-label {
        line-height: 25px;
    }
}

.search-placeholder-filtered {
    opacity: 1;
    font-weight: 600;
}

.search-placeholder-filtered input::placeholder {
    color: #3966f8;
    font-size: 16px;
}

@media screen and (max-width:767px) {
    .modalwidth__mobileview {
        .MuiDialog-paperWidthSm {
            min-width: 310px
        }
    }
}

@media screen and (max-width:767px) {
    .modalwidth-language {
        .MuiDialog-paperWidthSm {
            min-width: 310px
        }
    }
}

#client-pagination {
    display: flex;
    width: 100%;

    @media screen and (min-width: 600px) {
        padding: 0 15%;
    }

    li {
        flex: 1;
        font-size: 14px;
        width: unset !important;
        padding: 2px !important;
        text-align: center;

        button {
            margin: auto;
            color: #333333;
            width: unset;
            font-size: 13px;

            svg {
                font-size: 25px;
            }
        }

        button.Mui-selected {
            @media (hover: none) {
                background-color: rgba(0, 0, 0, 0.12) !important;
            }
        }
    }

    nav {
        flex: 2;

        ul {
            width: 100%;
            display: flex;
        }
    }
}

.plus-btn-client {
    button {
        color: white;
        padding: 9px 8px;

        svg {
            font-size: 36px;
        }
    }
}