.team-bot-dialog {
  .markdown-content {
    background-color: #f8f9fa;
    padding: 0.75rem;
    border-radius: 4px;
    margin-top: 0.25rem;

    p {
      margin: 0 !important;
      padding: 0 !important;
      min-height: unset !important;
      height: auto !important;
      display: block !important;
      font-size: inherit !important;

      &::before,
      &::after {
        content: none !important;
        display: none !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      & + * {
        margin-top: 0 !important;
      }
    }

    .team-bot {
      &-inline-code {
        background-color: #e9ecef;
        padding: 0.2rem 0.4rem;
        border-radius: 3px;
        font-family: 'Courier New', Courier, monospace;
      }

      &-code-block {
        &-wrapper {
          background-color: #e9ecef;
          padding: 2rem;
          border-radius: 4px;
          overflow-x: auto;
        }

        background-color: transparent;
        padding: 0;
        margin: 0;
      }

      &-ordered-list,
      &-unordered-list {
        margin: 0 !important;
        padding-left: 1.5rem !important;
        list-style-position: outside !important;
        display: block !important;
        line-height: 2rem !important;
        font-size: inherit !important;
        * {
          line-height: 2rem !important;
        }
      }

      &-ordered-list {
        counter-reset: item !important;
        list-style: decimal !important;
        list-style-type: decimal !important;

        .team-bot-list-item {
          display: list-item !important;
          list-style: decimal !important;
          list-style-type: decimal !important;
          margin: 0 !important;
          padding: 0 !important;
          line-height: 2rem !important;
          min-height: unset !important;
          height: auto !important;

          &::before {
            content: none !important;
          }
          &::after {
            content: none !important;
          }
        }
      }

      &-unordered-list {
        list-style: disc !important;
        list-style-type: disc !important;

        .team-bot-list-item {
          display: list-item !important;
          list-style: disc !important;
          list-style-type: disc !important;
          margin: 0 !important;
          padding: 0 !important;
          line-height: 2rem !important;
          min-height: unset !important;
          height: auto !important;

          &::before {
            content: none !important;
          }
          &::after {
            content: none !important;
          }
        }
      }

      &-list-item {
        margin: 0 !important;
        padding: 0 !important;
        display: list-item !important;
        visibility: visible !important;
        line-height: 2rem !important;
        min-height: unset !important;
        height: auto !important;
        border: none !important;
        background: none !important;

        &::before {
          content: none !important;
          display: none !important;
        }
        &::after {
          content: none !important;
          display: none !important;
        }
      }

      &-strong-text {
        font-weight: bold;
      }

      &-link {
        color: #3966f8;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &-heading {
        &-1,
        &-2,
        &-3,
        &-4,
        &-5,
        &-6 {
          margin: 0.75rem 0 0.25rem;
          font-weight: 600;
          line-height: 2rem;
        }
      }
    }

    blockquote {
      margin: 0.25rem 0;
      padding-left: 0.75rem;
      border-left: 4px solid #dee2e6;
      color: #6c757d;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      margin: 0.5rem 0;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 0.25rem 0;

      th,
      td {
        border: 1px solid #dee2e6;
        padding: 0.5rem;
        text-align: left;
      }

      th {
        background-color: #e9ecef;
      }

      tr:nth-child(even) {
        background-color: #f8f9fa;
      }
    }
  }
}
