.my-profile {
  padding: 10px 16px;

  .same-height-container {
    > div {
      > div {
        height: 100%;
      }
    }
  }
}