.copy-text-field {
  > div {
    border-radius: 4px;
    background: #fff;
    padding: 10px 12px 8px 12px;

    input {
      font-size: 15px;
      background: transparent;
      padding: 12px 0 12px 12px;
      color: #757575;
    }

    textarea {
      max-height: 200px !important;
      overflow-y: scroll !important;
      color: #757575;
    }

    textarea::-webkit-scrollbar {
      display: none;
  }

    fieldset {
      border: 1px solid #757575 !important;
    }
  }
}
