.standard-content-dialog {
  div[role="dialog"] {
    > div:first-child {
      padding: 0;

      .item-title, .item-details {
        padding: 0 16px;
      }
    }
  }
}