.lang-radio-group {
  flex-direction: row !important;
  justify-content: center;
  // padding: 5px 0;

  > label {
    margin: 0;
  }

  > label:first-child {
    > span {
      padding-left: 0;
    }
  }
}