.smart-idea-modal {
  .vip, .constellation {
    vertical-align: bottom;
  }

  .vip {
    width: 25px;
    margin-right: 5px;
  }

  .constellation {
    width: 20px;
    margin-left: 7px;
  }
}

.smart__idea_header {
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 0;
}

.cpi__key {
  padding-left: 7px;
}
.coverage__left {
  width: 50%;
  float: left;
}
.coverage__right {
  width: 50%;
  float: left;
  color: #3966f8;
  font-weight: 600;
  text-align: center;
}
.coverage__empty {
  text-align: center;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  .coverage__left {
    width: 45%;
    float: left;
  }
  .coverage__right {
    width: 50%;
    float: left;
    color: #3966f8;
    font-weight: 600;
    text-align: center;
  }
  .coverage__empty {
    width: 50%;
    float: left;
    text-align: center;
  }
}
