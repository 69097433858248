.payment-success {
	padding: 20px 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 170px);
	text-align: center;
	color: darkslategrey;;
	margin-top: 50px;

	.wrapper {
		svg {
			font-size: 100px;
		}

		> h3 {
			font-weight: bold;
			margin: 30px 0 15px 0;
		}
		> h5 {
			margin-bottom: 10px;
		}

		> button {
			min-width: 100px;
			font-weight: bold;
			font-size: 125%;
			padding: 8px 8px;
		}
	}
}