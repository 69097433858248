.NotificationCenterModal {
  // Block styles go here
  .MuiDialog-paperWidthSm {
    position: absolute;
    top: 2em;
    right: -1em;
  }
  .MuiDialog-paperScrollPaper{
    max-height: 60%;
    max-width: 40%;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0) !important;
}
  .ModalTitle {
    text-align: center !important;
    min-width: 290px;
    padding: 10px 24px;
  }
  .list {
    max-height: 55%;
    overflow-y: auto;
  }
  .IconImg {
    .NotificationBadge {
      .MuiBadge-dot {
        // margin-top: 3em;
        // margin-left: -1.5em;
      }
    }
    img {
      width: 50px !important;
      height: 50px !important;
    }
    margin-top: 1.1rem;
    margin-right: 1.1rem;
  }
  .item {
    // padding-left: 25px;
    // padding-right: 25px;
    cursor: pointer;
  }
  .bottomAction {
    text-align: center;
    padding: 8px 24px;
    cursor: pointer;
    h2 {
    font-size: 1.5rem;
    }
  }
  .MuiListItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: flex-start
  }
  .MuiTypography-body1 {
    font-size: 1.2em;
  }
  .MuiTypography-body2 {
    div {
      font-size: 1.4rem;
    }
  }
  .NotificationCenterModal .IconImg {
    margin-top: 0;
  }
  .MuiList-padding{
    padding-top: 0;
    padding-bottom: 0;
  }
  .message {
    display: block;
    position: relative;
    bottom: -10px;
  }
  .message-wrapper {
    margin-top: -1.5rem;
  }
  .message-content {
    font-size: 1.4rem !important;
    position: inherit !important;
    bottom: -10px !important;
    word-break: break-all;
    color: #888888 !important;
  }
  .message-sendTime {
    margin-top: 1rem;
    margin-bottom: 10px;
    position: relative;
    bottom: -10px;
    font-size: 1rem;
  }
  .h1, .h2, .h3, h1, h2, h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .MuiListItemText-multiline {
    word-break: break-word;
  }

  @media screen and (max-width: 750px) {
    .MuiDialog-paperScrollPaper{
      max-height: 65%;
      max-width: 90%;
    }
  }

  @media screen and (max-height: 800px) {
    .MuiDialog-paperScrollPaper{
      max-height: 70%;
    }
  }
}


