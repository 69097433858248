@import '../../views/App/palette.scss';

.fileSlide {
	> div {
		width: 110px;
	}
}

.iconContainer {
	position: relative;
	text-align: center;
	padding: 8px;
}

.icon {
	width: 50px;
}
