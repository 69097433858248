.corner-tri-wrapper {
	position: absolute;
	border-top-left-radius: inherit;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 0;
	overflow: hidden;

	.corner-tri {
		position: relative;
		z-index: 1;
		width: 0;
		height: 0;

		div {
			display: block;
			font-weight: bold;
			line-height: 1;
			color: white;
			position: absolute;
			text-align: center;
		}
	}
}
