.add-image-dialog {
	.wrapper {
		cursor: pointer;
		padding: 24px;
		text-align: center;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

		svg {
			color: grey;
			font-size: 56px;
		}
	}

	img {
		max-width: 100%;
	}
}