.bellButton {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: #ffd323;
}
.Badge {
    .MuiBadge-dot {
      margin-top: 0.5em;
      margin-right: 0.5em;
    }
    .MuiBadge-badge{
      min-width: 18px;
      height: 18px;
      top: 5px;
      right: 5px;
      font-size: 1.4rem;
    }
  }
