.team-news-dialog {
  --swiper-navigation-size: 27px;

  .content {
    padding: 0 !important;

    img {
      max-width: 100%;
      max-height: calc(100vh - 175px);
    }
  }
}

.team-news-dialog.new {
  img {
    width: 100%;
    height: 100%;
    //max-width: 50vw;
    //max-height: calc(90vh - var(--top-padding));
    object-fit: contain;
    cursor: pointer;
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-pagination {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0px);

    > span {
      margin: 0 4px;
    }
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 16px;
    left: auto;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 16px;
    right: auto;
  }
}
