.big-data-user {
  div.client-dashboard-number-div > div {
    position: relative;
  }

  .bday-chart-modal-content {
    max-height: 90%;
  }

  .birthday-chart-hint {
    text-align: center;
    margin: 0 0 10px 10px;
    color: darkgrey;
  }

  .client-dashboard-gender {
    position: relative;
    height: calc(100% - 14px);
    display: flex;
    flex-direction: column;
    margin-right: 0;

    .client-dashboard-gender-chart {
      flex-grow: 1;
    }

    .client-statistics-button {
      position: absolute !important;
      top: calc(-12px + 3%);
      right: calc(-12px + 3%);
      z-index: 1;
    }

    .client-dashboard-gender-overview {
      padding: 6px 0;

      img {
        width: 20%;
        max-width: 35px;
      }
    }
  }

  .grid-btn-container {
    height: 100%;
    .cardAnalysis {
      height: calc(100% - 14px);
      cursor: pointer;

      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > div:first-child {
          width: 100%;
          img {
            padding: 12px 0 5px 0;
            width: 60%;
            max-width: 50px;
          }
        }

        > div:nth-child(2) {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  div.client-dashboard-number-div {
    text-align: center;
    background-color: #9efff3;
    padding-top: 10px;
    max-width: 100%;
    width: unset;
    margin-top: 0;
  }

  .policy-category-tiles-wrapper {
    border-radius: 5px;
    padding: 7px 7px 0 7px;

    .swiper-container {
      padding-bottom: 7px;

      .swiper-pagination {
        bottom: -5px;
      }

      .swiper-button-disabled {
        opacity: 0;
      }

      .slick-arrow {
        z-index: 1;
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 0;
      }

      .slick-arrow:before {
        color: #3966f8 !important;
      }
    }

    .potential-biz-tiles {
      // background-color: #f9f8ff;

      div.client-dashboard-coverage-group p {
        font-size: 28px;
        > span {
          > span:nth-child(2) {
            font-size: 60%;
            line-height: 0;
          }
        }
      }
    }
  }
  div.colon {
    position: absolute;
    top: -5px;
    right: 5px;
    font-weight: bold;
    color: #9e9e9e;
    @media screen and (min-width: 600px) {
      right: 15px;
    }
  }
}