@import '../../../theme/variables.scss';

.disable__consent {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #3966f8;
	background: #e0e0e0 !important;

}
.client__status--details {
	display: inline-block;
    padding: 0 10px;
    font-size: 14px;
}
.no__coverage {
	> li {
		align-items: center !important;
		padding: 2px 0 !important;
	}
}

.contact__modal {
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0;
	margin-top: 20px;

	> li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 0;

		img {
			height: 20px;
		}

		.center-col {
			width: 60%;
		}
		.center-col-right{
			width: 35%;
			text-align: right;
			color:#888;
			svg {
				width: 20px;
				height: 20px;
				vertical-align: text-top;
			}
		}
		.center-col-left-cpi {
			display: inline-block;
		}
		.center-col-left, .center-col-left-cpi{
			width: 60%;
			text-align: left;
		}

		button:not(.plan-type-select-button) {
			background: #3966f8;
			color: #fff;
			border: 0px solid #3966f8;
			padding: 2px 0 1px 0;
			border-radius: 2px;
			margin-left: 5px;
			width: 44px;
			text-align: center;
		}
	}

	.contact-disabled {
		background: #C0C0C0 !important;
	}

	> li span button.smart-key-modal{
		padding: 4px 0 4px 0;
		border-radius: 15px;
		position: relative;
		top: -5px;
		width: 35px;
	}
}

.leftside__images {
	margin-right: 0px !important;
}

.portfolio-right-item {
	width: 90%;
	word-break: break-word;
}

.whatsapp-icon {
	margin: 4px 2px !important;
}

.rightside {
	float: right;
}

.reminder--checkbox {
	position: relative;
}

.client-reminder-color.input-group-option-select label > span:nth-child(2) {
	color: #000;
}

.change__btn {
	text-align: right;
	position: absolute;
	top: -22px;
	right: 0px;
	font-size: 12px;

	input {
		width: 60px;
	}
}

.change__btn_default {
	text-align: right;
	position: absolute;
	top: -22px;
	right: 0px;
	font-size: 12px;

	input {
		width: 60px;
		background: #e7e7e7 !important;
	}
}

.factsheet--checkbox {
	top: -16px;
	// height: 30px !important;
}

span.checkbox_back-color {
	background: #f5f5f5;
	right: -5px;
	// top: -13px;
}
.checkbox-top{
	top: -13px;
	
}

span.checkbox-right {
	position: absolute;
	right: 0;
	width: 32px;
	// height: 38px;
	background: #fff;
}

.label-font {
	font-size: 14px;
}

.fontWeight--none {
	padding-right: 0;
	margin-bottom: 15px;
}

.sms-border {
	border: 1px solid #ccc;
	padding: 10px;
	margin: 0px;
	border-radius: 3px;
	background: white;
	max-height: 220px;
	overflow-y: auto;
}


@media (max-width: 320px) {
	.hide-icon-xs {
		display: none;
	}

	.checkbox-right {
		width: 20px !important;

		.form-group {
			right: 13px !important;
			position: relative !important;
		}
	}
	.padding__left-0 {
		padding-left: 0px;
		font-size: 13px;
	}
	// .checkbox_back-color {
	// 	right: -5px !important;
	// }
}

.bar-edit {
	border-color: transparent !important;
}

#clientFactSheetDetailCSS {
	.number-of-child {
		padding: 5px 14px 2px;
	}

	.option-select-button {
		min-width: 48px;
	}

	.head {
		margin-top: 95px;
	}
}

@media(max-width: 767px) {
	.contact__modal {
		> li {
			.center-col-right{
				width: 35%;
				text-align: right;
				color:#888;
			}
			.center-col-left, .center-col-left-cpi{
				width: 60%;
				text-align: left;
			}
		}
	}
}

.personal_form {
	.source-button {
		min-width: 100px;
	}
}

@media(max-width: 375px) {
	.personal_form {
		.source-button {
			min-width: 80px;
		}

		button {
			margin: 2px !important;
		}
	}
}

.caseclosing_padding {
	padding-left: 0;
	padding-right: 0;
}

//custome input css::START

.case-close-met {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.caseclose-datepicker-form {
	margin-top: 20px;

	.form-group {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.control-label {
			position: inherit;
			/*width: 50%;*/
			top: auto;
			bottom: auto;
			margin: 0;
			color: #000;
		}

		.caseclose-datepicker {
			position: inherit;
		}

		input {
			color: #000 !important;
		}
	}
}

.caseclose-form-business {
	.form-group {
		flex-direction: column;
	}

	.adv_biz_modal_key {
		margin-bottom: 0px;
		padding-left: 0;
	}
}

.d-flex-box {
	label {
		span {
			font-size: 14px !important;
		}
	}
}

.d-flex-box-lang {
	label {
		> span {
			width: 28px;
		}

		span + span {
			width: 60px;
			font-size: 14px !important;
		}
	}

}


.d-flex-box-lang-sms {
	label {
		> span {
			width: 28px;
			padding-top: 28px;
			padding-left: 10px;
		}

		span + span {
			width: 60px;
			font-size: 14px !important;
		}
	}

}

.graph__details--target {
	.business-analysis-month {
		max-width: 100%;

		li {
			text-align: left;
			width: 100%;
			max-width: 100%;
		}
	}
}

.caseclose-form {
	.form-group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		//flex-direction: column;
		.control-label {
			position: inherit;
			/* width: 50%; */
			bottom: auto;
			align-items: center;
			color: #000;
			margin: 0;
			top: 0;
		}

		.form-control {
			border-bottom: 1px solid gray;
			text-align: left;
		}
	}
}

.portfolio-default-button {
	display: inline-block !important;
	min-height: 27px !important;
	min-width: 27px !important;
	border-radius: 3px !important;
	margin-left: 8px !important;
	margin-right: 2px !important;
	text-transform: capitalize !important;
	//font-size: 14px !important;
	padding: 4px 4px 3px 4px !important;
	box-shadow: none !important;
}

.case_styling {
	font-weight: 800;
	font-size: 15px;
}

.tracking_ol > li {
	list-style: decimal;
	width: 148px;
	text-align: left;
	margin: auto;
}

.client {
	width: 100%;

	.client-search {
		width: 100%;
		z-index: 1;
		border-bottom: solid #cccccc88 1px;

		.client-select {
			width: calc(40% + 18px) !important;
			padding: 2px 0px 2px 5px;
		}
	}

	.client-container {
		background-color: #fff;

		.page-header {
			top: 49px;
			background-color: #fff;
			margin: 0;
			padding: 0;
			display: flex;
			z-index: 1001;
		}
		.page-body {
			min-height: calc(100vh - 168px);

			.clientName, .clientNameHidden {
				position: relative;

				.portfolio-right-item {
					width: 75%;
					display: inline-block;
				}

				.portfolio-add-sign {
					position: absolute;
					right: 7px;
					cursor: pointer;

					img {
						width: 25px;
						height: 25px;
					}
				}
			}
		}

	}

	.portfolio-add-sign {
		@media (max-width: 320px) {
			margin-right: 0px !important;
		}
	}

	.imageClientState {
		@media (max-width: 320px) {
			margin: 4px;
		}
	}

	.client-list-icon {
		.client-list-icon-grp {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.left-right-box {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 10px 0;
	}
}

.case-open-datepicker {
	max-width: 100% !important;
	width: 113px !important;
	margin-bottom: 12px !important;

	input {
		color: #3966f8 !important;
		margin-bottom: 2px !important;
	}
}

.hide-option {
	display: none !important;
}

.margin_left-0 {
	margin-left: 0px !important;
}

.number__formet {
	width: 102px !important;
}

.remindMeDate input {
	text-align: left;
}
.edit__pen {
	color: white !important;
	position: absolute !important;
	z-index: 1043;
    right: 0;
	bottom: 0;
	span{
            svg {
                font-size: 22px !important;
            }
	}
}

.client_smart-budge{
	position: relative;
	display: inline-block;
	float: right;
}
.client_badge  > span{
	top: -6px;
    width: 6px;
    height: 6px;
    right: -23px;
}
.client_badge .MuiBadge-dot {
    min-width: 6px;
}
@media(max-width: 413px) {
	.disc-default-button{
		margin-right: 1px !important;
		margin-left: 2px !important;
	}
}

@media screen and (max-width:767px){
    .modalwidth__mobileview{
        .MuiDialog-paperWidthSm{
            min-width: 310px
        }
    }
}

button.plus-btn-client {
	position: absolute;
	bottom: 0;
	z-index: 1043;
	right: -6px;
}