.covid-hint-dialog {
	.content {
		white-space: pre-wrap;

		img {
			width: 20px;
			vertical-align: text-top;
		}

		button {
			line-height: 1.25;
		}

		.disclaimer {
			font-size: 75%;
			color: #888888;
		}
	}
}