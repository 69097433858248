.pfa {
	min-height: 100vh;
	background: #f2f9ff;

	.pfa-container {
		padding: 16px 16px 0 16px;
	}

	.powered-by {
		p {
			font-size: 13px;
		}
	}

	.calculator-profiles {
		.save-button {
			display: flex;
			justify-content: flex-end;
		}
		.MuiGrid-grid-xs-6 {
			display: flex;
			justify-content: center;
		}
		.MuiGrid-grid-xs-3 {
			display: flex;
			justify-content: center;
		}
		.profile-buttons {
			width: 100% !important;
		}
	}

	.pf-graph-card-container {
		.MuiSelect-root {
			padding: 8px 32px 8px 16px;
		}
		.MuiTypography-body1 {
			font-size: 18px;
			color: grey;
		}
		> div {
			padding-top: 8px;
		}
	}

	.access-code-card {
		margin: 16px 16px 0;
	}

	.top-hint {
		text-align: center;
		margin-bottom: 10px;

		a {
			color: grey;

			:last-child {
				font-size: 75%;
				opacity: 0.75;
			}
		}
	}

	@media screen and (max-width: 350px) {
		font-size: 80%;
	}

	.pfa-card {
		border-radius: 12px;

		> div {
			padding-bottom: 8px;
		}
	}

	.collapse-rows {
		td.MuiTableCell-root {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.collapse-rows.MuiTableRow-root {
		height: 0;
	}

	.pfa-item-details-card {
		.MuiCardContent-root::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		> div {
			padding: 8px 8px;
			.MuiGrid-container {
				width: 100%;
				margin: 0;
			}
			.grid-container {
				> * {
					padding: 4px 4px 4px 12px;
				}

				> :first-child {
					border-right: 1px solid #9EA6B9;
				}
			}
		}
		.main-rows {
			.MuiTableCell-root {
				border-bottom: none;
			}
		}
		.MuiCardContent-root {
			overflow: auto;

			.MuiTableContainer-root {
				min-width: 680px;
			}
		}
		.MuiTableCell-root div.profit-rate {
			display: flex;
			margin-right: 10px;
			margin-left: 10px;
			padding-left: 8px;
			padding-right: 8px;
			justify-content: center;
			align-items: center;
			max-width: 70px;
			min-height: 25px;
			color: white;
			background-color: var(--new-theme);
			border-radius: 7px;
		}
		.collapse-rows.MuiTableRow-root {
			.MuiCollapse-wrapperInner {
				padding-bottom: 10px;
			}
		}
		.MuiTableCell-body .MuiCollapse-wrapperInner{
			font-size: 80%;
			color: gray;
		}
		.MuiCollapse-wrapper {
			padding-top: 0px;
		}
		.MuiTableCell-root {
			padding: 8px;
			font-size: 14px;
			min-width: 90px;
		}
		.MuiTableCell-root:first-child {
			min-width: 35px;
		}
	}

	.pfa-item-details-card.orange {
		.title-panel .title > div, .value-wrapper {
			color: #FFA427;
		}

	}

	.pfa-item-details-card.blue {
		> div {
			padding: 0;
		}

		.left {
			padding: 28px 8px 28px 20px;
			border-right: 0;

			.title-panel .title > div, .value-wrapper, .title-panel .details  {
				color: #3966f8;
			}
		}

		.right {
			background: #3966f8;
			padding: 28px 12px 28px 12px;

			.title-panel .title > div, .value-wrapper, .title-panel .details {
				color: #ffffff;
			}
		}
	}

	.pfa-return-rate {
		background-color: #3966f8;
		color: #ffffff;

		> div {
			padding-bottom: 16px;
			.title {
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0;
				.pfa-item-details {
					.title-panel {
						font-size: 130%;
						font-weight: 700;
						margin: 0;
						.MuiIconButton-root {
							color: #fff;
						}
						.details {
							font-size: 50%;
							font-weight: 400;
							color: white;
							min-height: unset;
						}

						div[role="button"][aria-hidden] {
							color: white;
							right: -18px;
						}
					}

					.value-wrapper {
						font-weight: 500;
						font-size: 50%;
					}
				}
			}

			.value-wrapper {
				display: flex;
				align-items: center;

				.formula {
					font-size: 85%;
				}

				.rate {
					font-size: 250%;
					font-weight: bold;
					margin-top: 5px;
				}
			}
		}
		.MuiCardContent-root {
			padding: 16px 62px;
			.MuiGrid-spacing-xs-8 > .MuiGrid-item {
				padding: 32px 0;
			}
			.MuiIconButton-root {
				padding:0;
			}
		}
	}

	.disclaimer {
		padding: 2px 0px 12px;
		font-size: 90%;
		opacity: 0.9;
	}
}

@media screen and (min-width: 960px) {
	.pf-side-input-panel {
		.custom-input-select-inner {
			font-size: 120%;
			padding-left: 7px;
		}
		.MuiInput-input {
			font-size: 120%;
			padding-left: 7px;
		}
	}
}

@media screen and (max-width: 1050px) {
	.pf-side-input-panel {
		.custom-input-select-inner {
			font-size: 14px;
			padding-left: 0px;
		}
		.MuiInput-input {
			font-size: 14px;
			padding-left: 0px;
		}
	}
}

.pf-side-input-panel {
	.custom-input-select-inner, .MuiInput-input {
		font-weight: 600;
		color: #3966f8;
		height: 20px;
	}
	.input-control.cash-value-at label {
		transform: translate(0, -0.5px) scale(1);
	}
	div.custom-input-select {
		width: 100%;
		padding-right: 6px;
	}
}

.pfa-input-panel {
	.MuiCardContent-root {
		padding-top: 16px;
		padding-bottom: 6px !important;
	}
	.MuiInput-input {
		font-weight: 600;
		color: #665EFF;
		font-size: 120%;
		padding-left: 7px;
	}
	.MuiInputBase-inputAdornedEnd {
		max-width: 40%;
	}
	.MuiInputBase-adornedEnd > div {
		color: grey;
		font-size: 110%;
	}
}

.pf-loan-interest-input-panel {
	.MuiCardContent-root {
		margin-top: 5px;
		padding-bottom: 0 !important;
		padding-top: 0 !important;
	}
	.MuiInput-input {
		font-weight: 600;
		color: #665EFF;
		font-size: 120%;
		padding: 0 2px;
	}
	.MuiTableCell-root {
		padding: 8px;
		font-size: 11px;
		border-bottom: none;
		text-align: center;
		.MuiInput-input {
			color: grey;
		}
	}

	.MuiTableCell-root:first-child {
		width: 20%;
		min-width: 30px;
		padding-left: 0;
		padding-right: 0;
	}

	.MuiTableCell-root.loan-percentage {
		width: 20%;
	}

	.MuiTableCell-root.term-add-on {
		width: 60%;
	}

	.MuiTableCell-root.loan-percentage .MuiInputBase-root {
		min-width: 66px;
	}
	.MuiTableCell-root.term-add-on .MuiInputBase-root {
		min-width: 50px;
	}
	.MuiTextField-root .MuiIconButton-root {
		padding: 0px;
		margin-left: 0px;
		input {
			min-width: 70%;
		}
		svg {
			font-size: 19px !important;
		}
	}
	.MuiCollapse-wrapperInner {
		text-align: left;
	}
	.collapse-rows {
		td.MuiTableCell-root {
			color: grey;
		}
	}
	.mthly-interest {
		color: grey;
	}
}
