.confirm-add-policy-dialog {
  [role="dialog"] {
    width: calc(100% - 36px);
    margin: 18px;

    .next-actions {
      text-align: center;
      button {
        width: 100%;
        max-width: 130px;
        padding: 5px;

        img {
          height: 20px;
        }
      }
    }
  }
}