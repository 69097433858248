.policy-category-table.edit {
  button {
    padding: 4px;

    svg {
      font-size: 18px;
    }

    img {
      width: 17px;
      height: 17px;
    }
  }
}