div.pro-required-card {
	background-color: #FFCC00;
	color: #000;

	> div {
		padding: 16px !important;

		p {
			font-weight: 500 !important;

			span {
				text-decoration: underline;
			}
		}
	}
}