.portfolio-chart {
  position: relative;
  padding-top: 7px;
  height: 100%;

  > div {
    height: 100%;

    .Outer {
      height: 100%;
    }
  }
}