.setting-button,
.savingpage-setting-button {
  position: absolute !important;
  min-width: 40px !important;
  max-width: 40px !important;
  padding: 1px 4px !important;
  margin-top: 9px !important;
}
.CI-button {
  margin-top: -2px !important;
}
.setting-button {
  left: 0;
}
.savingpage-setting-button {
  left: 7px;
}
.analysis-input-groups span svg {
  width: 0.85em;
  height: 0.85em;
}
div.analysis-saving-show-at-slider {
  width: 90%;
  margin: 0 auto 0;
}

/* .ci-input-panel , .life-input-panel, .saving-input-panel{
  width: 70%;
} */

@media only screen and (min-width: 767px) {
  .setting-button {
    left: 7px;
  }
}

.client-analysis-top-bar {
  top: 105px;
}
