.top-nav-help-dialog {
    [role="dialog"] {
        min-width: 265px;
    }

    button {
        padding: 8px;
        margin-bottom: 12px;

        >span {
            justify-content: flex-start;

            img {
                width: 50px;
                height: 50px;
            }

            p {
                width: 100%;
                font-size: 18px;
            }
        }
    }
}