.home-page.adviser {
  overflow: hidden;
  > div:not(.swiper-container):not(.dashboard-guideline-icon) {
    padding: 0 16px;
  }

  .swiper-container {
    padding: 5px 0 15px 0 ;

    .swiper-wrapper {
      > div {
        box-shadow: 1px 1px 5px 0 rgba(109, 109, 109, 0.16);
        > div {
          box-shadow: none;
        }

        img {
          width: 100%;
        }
      }
    }

    .swiper-pagination-bullets {
      bottom: -3px;
    }
  }

  .hot-features, .newbie-lessons {
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 120%;
  }

  .swiper-slide {
    border-radius: 6px;

    > div {
      border-radius: 6px;
    }
  }

  .swiper-slide:not(.swiper-slide-visible) {
    visibility: hidden;
  }
}