.target-editor-modal .remind-me-check-box {
  text-align: right;
}

.target-editor-modal .remind-me-check-box > label {
  margin-right: 0;
}

.target-editor-modal .rdw-editor-main * {
  text-align: center;
}
