.location-control {
  //textarea:first-child {
  //  padding-right: 30px;
  //}
  //
  > div {
    //position: absolute;
    right: 0;
    button {
      padding: 4px;
    }
  }
}