.portfolio-subcategory:before {
  display: none;
}

.portfolio-subcategory {
  margin: 10px 0 !important;
  transition: none !important;

  .summary {
    padding: 5px 15px 5px 10px;
    min-height: unset !important;
    color: #000000DE;

    .icon {
      width: 20px;
      height: 20px;
      svg {
       font-size: 20px;
      }
      img {
        width: inherit;
        height: inherit;
        vertical-align: unset;
      }
    }

    > :first-child {
      margin: 9px 0;

      > div {
        > :first-child {
          text-align: left;
          font-size: 16px;
        }

        > :nth-child(2) {
          text-align: right;
        }
      }
    }

    > :last-child {
      padding: 6px;
    }
  }

  .details {
    padding: 0;
    color: #0000008A;

    > div {
      width: 100%;
      padding: 0;

      > div {
        border-top: 1px dashed #f1f1f1;
        padding: 0;

        .policy-entry {
          width: 100%;
        }
      }
    }
  }
}