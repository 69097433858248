.subscription {
	height: 100vh;

	.moreInfoPadding {
		padding-left: 16px;
		padding-right: 16px;
	}

	> #wrapper {
		height: 100vh;
	}
}

@media screen and (min-width: 960px) {
	.subscription {
		position: fixed;
		width: 100vw;
		height: 100vh;
		overflow: scroll;
		left: 0;
	}
}

.payment-info-modal, .fs-new-sub {
	#page-content-wrapper {
		padding: 50px 0 15px 0;
	}
}