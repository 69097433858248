.login-demo-wrapper {
  height: 100vh;
  text-align: center;
  padding: 16px;
  background: #f5f5f5;
}

.login-demo-card {
  padding: 15px 0;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
}

.login-demo-wrapper button {
  margin: 10px;
  text-transform: unset;
  padding: 5px;
  width: 30%;
  font-size: 1.25rem;
}
