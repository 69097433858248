.my-profile {
  .name-card {
    .left {

    }

    .right {
      > div {
        > div {
          width: 100%;
        }
      }
    }
  }
}