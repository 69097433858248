.pro-badge {
	display: inline-block;
	background: #ffcc00;
	border-radius: 3px;
	font-weight: bold;
	font-size: 50%;
	padding: 3px;
	line-height: 1;
	vertical-align: middle;
}

.title .pro-badge {
	position: absolute;
	top: 50%;
	transform: translate(5px, -50%);
}