.analysis-swipeable-view {
  margin-top: 93px;
}

.analysis-slide {
  min-height: calc(100vh - 225px);
}

/* .analysis-overall {
  .analysis-overall margin-top: 0;
} */

.analysis-overall .cardAnalysis {
  margin-left: auto;
  margin-right: auto;
}

.analysis-overall .cardAnalysis canvas {
  width: 100%;
}

div.analysis-input.inline {
  width: fit-content;
  min-width: 45px;
  text-align: left;
  padding-right: 0;
  margin: 0;
  display: inline;
}

div.analysis-input.inline div.custom-input-select {
  padding-left: 5px;
}

div.analysis-input.inline .custom-input-select-inner {
  padding-right: 24px;
}

h4.no-margin {
  margin: 0;
}

/* for safari gen */
#wrapper.safari-gen #page-content-wrapper {
  padding-top: 12px;
  padding-bottom: 12px;
}

.analysis-slide.safari-gen {
  padding-top: 0;
  background-color: #f5f5f5;
}

.analysis-slide.safari-gen div.analysis-expansion.analysis-input-group,
.analysis-slide.safari-gen div.analysis-input-group-detail-collapse {
  position: relative;
  width: 100%;
  top: unset;
  left: unset;
}

.analysis-input {
  input[name='currentAsset'], input[name='customTopUp'] {
    padding: 0;
  }
}

.analysis-ranking .main-text {
  margin: -10px 0 -20px 0;
  font-size: unset;
}

div.export-expansion {
  background-color: rgba(242, 245, 255, 1);
}

.export-expansion .analysis-expansion-detail {
  padding: 10px 5px;
}

.export-expansion .analysis-expansion-detail.analysis-expansion-user-detail {
  background-color: rgba(255, 255, 255,0.97);
}

.height-block-for-fab {
  height: 35px;
}

.policyTitle{
  padding-top: 30px;
  padding-bottom: 120px;
  max-width: 975px !important;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.premium-protection-container{
  padding-top: 70px;
  padding-bottom: 120px;
  max-width: 675px !important;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 675px) {
  div.analysis-expansion.premium-protection-input-panel, div.analysis-expansion.premium-protection-input-panel .analysis-input-group-detail-collapse {
    max-width: 665px;
    left: 50% !important;
    transform: translate(-50%);
    width: 100% !important;
  }
}

@media only screen and (min-width: 960px) {
  div.analysis-expansion.premium-protection-input-panel {
    margin-left: 30px;
  }
}

div.analysis-expansion.premium-protection-input-panel {
  top: calc(55px + var(--top-padding));
  left: 8px;
}

div.analysis-expansion.premium-protection-input-panel, div.analysis-expansion.premium-protection-input-panel .analysis-input-group-detail-collapse {
    width: calc(100% - 16px);
}

.age-text {
  margin-left: 5px;
  // color: #2e8dc4;
}

.analysis-input-panel {
  .policy-picker-info {
    margin-bottom: 16px;
  }
}
