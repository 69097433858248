.meeting-dialog {
  [data-rsbs-header] {
    padding-top: 20px;
  }

  [data-rsbs-header]:before {
    top: 8px;
  }

  [data-rsbs-has-header='false'] [data-rsbs-header] {
    padding-top: 12px;
  }

  .location {
    margin-bottom: 15px;

    .location-control {
      width: 100%;
      padding-bottom: 0;
    }
  }
  .day-count {
    text-align: center;
  }
  .datepicker-row {
    display: flex;
    align-items: center;
  }
  .case-open-datepicker.tracking-start-datepicker {
    width: 100% !important;
    input {
      font-size: 14px !important;
      color: #333 !important;
    }
  }
  .datepicker-group {
    .date-field {
      display: inline-block;
      max-width: 260px;
      width: 100%;
      border-bottom: 1px solid #D3D3D3;
      > div {
        display: flex;
        width: 100%;
        height: 36px;
        margin-top: 5px;
        align-items: center;
        margin-right: 24px;
        @media screen and (max-width: 600px) {
          padding-right: 36px;
        }
      }
    }
    .date {
      text-align: center;
      display: inline-block;
      font-size: small;
      min-height: 21px;
      flex: 1;
    }
    .reminder-disabled:hover {
      background: none;
      cursor: default;
    }
    .reminder-disabled-icon {
      visibility: hidden;
    }
    .reminder-enabled-icon {
      color: rgba(0, 0, 0, 0.54);
    }
    .reminder-checkbox {
      margin-top: -2px;
    }
  }
}
