.footer-nav {
    padding-bottom: var(--bottom-padding);
}

.footer-nav.side-nav {
    background-color: #f8fBff;
    bottom: unset !important;
    top: calc(45px + var(--top-padding));
    left: 0;
    width: 60px !important;
    height: calc(100% - 45px - var(--top-padding));
    padding-bottom: calc(15px + var(--bottom-padding));
    overflow: scroll;
    ul {
        -webkit-flex-flow: column wrap !important;
        -moz-flex-flow: column wrap !important;
        -ms-flex-flow: column wrap !important;
        flex-flow: column wrap !important;
    }
    .small-font {
        font-size: 0.61em !important;
    }
    .new-icon {
        height: 33px !important;
    }
}

.footer-nav.side-nav::-webkit-scrollbar {
    display: none;
}

.footer-badge > span {
    right: -1px !important;
}

.footer-nav ul li > div:active {
    background-color: #3966f82b;
}
