.clientDash.advisorCard {
  padding-bottom: 15px;
}

.adv-dash-card {
  position: relative;
  text-align: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

button.edit-button {
  padding: 2px 6px;
  min-width: 30px;
  min-height: 20px;
}

button.edit-button svg {
  height: 0.6em;
  width: 0.6em;
}

div.plus-btn-client {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1043;
}

div.plus-btn-openGraph {
  position: absolute;
  bottom: 0;
  right: 80px;
  z-index: 1043;
}

div.dashboard-guideline-icon > button > span > svg {
  width: 27px !important;
  height: 27px !important;
  color: #fff;
  font-size: 0px !important;
}

div.dashboard-guideline-icon  {
  right: 33px !important;
  z-index: 1201 !important;
  button {
    padding: 9px 5px !important;
  }
  @media (min-width: 600px) {
    right: 49px !important;
    button {
      padding: 9px 8px !important;
    }
  }
}

div.client-dashboard-guideline-icon > button {
  padding: 9px 8px !important;
}

div.client-dashboard-guideline-icon > button > span > svg {
width: 27px !important;
height: 27px !important;
color: #fff;
font-size: 0px !important;
}

.notification-icon {
  position: fixed;
  z-index: 1200;
  right: 0;
  top: -0.4rem;
  padding: 1rem;
}

.notification-icon-user {
  position: fixed;
  z-index: 1200;
  right: 3.3rem;
  top: -0.1rem;
  padding: 1rem;
}
.notification-toast{
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 4px solid #3498db;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    .Toastify__close-button{
      color: #3966f8
    }
}
.Toast-title{
  word-break: 'break-word' !important;
  color : black;
    .MuiTypography-body1{
      font-size: 1.6rem !important;
      }
}
@media only screen and (max-width: 480px) {
  .notification-toast{
    margin: 5px;
  }
}

div.more-functions {
  width: 100%;
  box-shadow: none !important;
}

.user-view-only .clientDash.clientDashPadding {
  padding-bottom: 410px;
}

.adv-dash-chart-title{
  color:#0000008a;
}