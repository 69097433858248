.dashboard-drawer {
    max-width: 400px;
    min-width: 400px;
    height: 100vh;
    overflow: auto;
    padding-bottom: 100px;
    background-color: white;
    // justify-content: center;
    img {
        max-width: 400px;
    }
    @media screen and (min-width: 960px){
        margin-top: calc(45px + var(--top-padding));
        position: fixed;
        top:0;
        right: 0;
    }
}
.dashboard-drawer::-webkit-scrollbar {
    display: none;
}
.dashboard-drawer-title{
    font-size: 15px;
    font-weight: 600;
    padding-top: 15px;
    @media screen and (min-width: 960px){
        z-index: 1300;
        position: fixed;
        min-width: 400px;
        max-width: 400px;
        background-color: #fff;
        min-height: 38px;
        padding: 16px 6px 12px;
    }
}

.dashboard-drawer-graph-container{
    // height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 960px) {
        margin-top: calc(35px + var(--top-padding));
        padding-bottom: calc(50px + var(--bottom-padding));
    }
    @media screen and (max-width: 960px){
        // height: calc(100vh - 100px);
    }
}

.dashboard-drawer-icon {
        margin-right: 0px;
        margin-top: -50px;
        z-index: 1299;
        position: relative;
        float: right;
        width: 25px;
    @media screen and (min-width: 600px){
        margin-right: 10px;
        margin-top: -50px;
    }

    @media screen and (min-width: 960px){
        margin-right: 10px;
        margin-top: -44px;
    }
}

.dashboard-drawer-icon > button {
    padding-top: 9px;
    padding-right: 8px;
    // top: -42px !important;
    // right: 15px !important;
    @media screen and (max-width: 960px){
        padding: 17px;

    }
}

.dashboard-drawer-icon > button > span > svg{
    width: 27px !important;
    height: 27px !important;
    // color: #fff;
    font-size: 0px !important;
}

.coverage-graph-title{
    color:#0000008a
}

.exclamation-chart-wrapper{
    position: absolute;
    right: 88px;
    top: 50px;
    opacity: 0.5;
    width: 50%;
    z-index: 1;
    @media screen and (max-width: 720px){
        right: 80px;
    }
}

.exclamation-chart{
    width: 70px;
}

.moreInfoDot{
    top: 0px;
    right: -15px;
    @media screen and (max-width: 720px){
        top: 7px;
        right: -7px;
    }
}
