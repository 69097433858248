.custom-filter-switch {
    height: 100%;
  
    > div {
      height: 100%;
  
      > div {
        max-width: unset;
  
        button {
          height: 100%;
          min-width: 60px;
          min-height: 32px;
          @media screen and (max-width: 400px) {
            min-width: 50px;
          }
          padding: 0 4px;
          border-radius: 0;
          border: none;
          border-left: none;
          border-top: 2px solid lightgrey;
          border-right: 2px solid lightgrey;
  
          .text {
            max-width: 75px;
            @media screen and (max-width: 600px) {
              max-width: 60px;
            }
            @media screen and (max-width: 400px) {
              max-width: 45px;
            }
            font-size: 100%;
            line-height: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          img {
            width: 25px;
          }
        }
      }
  
      > div:first-child button {
        border-left: 2px solid lightgrey !important;
      }
    }

  }