.my-profile {
  .subscription-card {
    > div {
      > div {
        width: 100%;

      }

      > div:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}