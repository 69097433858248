.large-icon-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.large-icon-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
}