.access-code-card {
	.logo {
		cursor: pointer;
	}

	.heading {
		margin-bottom: 2px;
	}

	.hint {
		font-size: 80%;
		opacity: 0.5;
		margin-top: 5px;
	}

	.access-code-input-wrapper{
		max-width: 600px;
		margin: 0 auto;
	}

	.button-wrapper {
		width: 100%;
		text-align: right;
		margin-top: 8px;

		.submit-button {
			text-transform: unset;
			min-height: unset;
		}
	}
}