.upload-avatar-dialog {
  div[role="dialog"] {
    margin: 32px 8px;
    width: calc(100% - 16px);
  }

  input[type="file"] {
    display: none;
  }

  input[type="range"] {
    padding: 0 8px;
  }
}