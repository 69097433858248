@import './theme/global.scss';
@import './variables.scss';

html {
  height: 100%;
}

body {
  height: 100%;
  //height: auto;
  color: #616161;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-family: 'Roboto', sans-serif !important;
  background: #FFFFFF;
  padding-top: var(--top-padding);

  #root {
    height: 100%;

    .App-pageContainer {
      height: 100%;

      > div > div:last-child {
        height: 100%;
      }
    }
  }
}

body.blur-4px > *:not(.blur-except) {
  filter: blur(4px);
}

div[role="presentation"] .MuiDialog-paperFullScreen, div[role="presentation"] .MuiDrawer-paper, div[role="presentation"] .MuiAppBar-root {
  padding-top: var(--top-padding);
}

.Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right,
MuiSnackbar-anchorOriginTopCenter, MuiSnackbar-anchorOriginTopLeft, MuiSnackbar-anchorOriginTopRight {
  top: var(--top-padding);
}


*,
html {
  outline: 0;
}

a,
a:focus,
a:hover {
  color: #000;
}

input {
  border-radius: 0;
}

legend {
  border-bottom: 0;
}

.nav-pad {
  padding: 60px 0 calc(50px + var(--bottom-padding));
}

.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  cursor: pointer;
}

.highlight {
    color: var(--primary-main);
    font-weight: bold;
}

.link-highlight {
    color: var(--primary-main);
    text-decoration: underline;
    cursor: pointer;
}

.pro-text {
  color: var(--primary-main);
  vertical-align: middle;
}

.gradient-bg {
  #page-content-wrapper {
    //background: linear-gradient(0deg, #fff -8%, #5bb1ff);
    background: #fff;
    min-height: 100%;

    .page-title {
      font-weight: bold;
      margin: 8px 0;
    }
  }
}

button.fixed-icon-button {
  width: 48px;
  height: 48px;
  padding: 0;
}

// for making short content center in mui dialog
div.dialog-content-centered {
  display: flex;
  @media screen and (min-width: 960px) {
    min-width: 400px;
  }

  > div {
    margin: auto 0;
    width: 100%;
  }
}

button.darkgrey:not([disabled]) {
  color: #fff !important;
  background-color: #b0b0b0 !important;
}

button.white:not([disabled]) {
  color: #3966f8 !important;
  background-color: #ffffff !important;
}

button.orange:not([disabled]) {
  color: #fff !important;
  background-color: #fbb63c !important;
}

button.wtsapp-green:not([disabled]) {
  color: #fff !important;
  background-color: #128c7e !important;
}

button.dark-green:not([disabled]) {
  color: #fff !important;
  background-color: #0fa2b8 !important;
}

button.light-green:not([disabled]) {
  color: #fff !important;
  background-color: #73BB4B !important;
}

.custom-chart-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 5px;
    }

    .text {
      font-size: 11px;
      line-height: 1;
      margin-right: 3px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.referral-count {
  span {
    font-size: 12px;
    top: -15px;
  }
}

.phone-text-field {
  .rrui__input, .rrui__input-label {
    height: calc(0.3rem * 9);
  }

  .rrui__select__options {
    z-index: 2;
  }

  .phone-number-input-wrapper .react-phone-number-input__icon {
    width: 1.5em;
    height: 1.5em;
  }

  .phone-number-input-wrapper {
    height: 2em;
  }

  label.non-shrink {
    transform: translate(0, 0) scale(1);
  }

  > div::before, > div::after {
    z-index: 1;
  }
}

button.reminder-date-add-todo-icon-btn {
  @media screen and (min-width: 601px) {
    display: none;
  }
}
button.reminder-date-add-todo-btn {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
  z-index: 1300;
}
