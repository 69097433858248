.pfa-input-panel, .pf-side-input-panel, .pf-loan-interest-input-panel {
	.input-control > div > label.focused, .input-control > div > div > input, .labelled-slider > :last-child > div > input {
		color: #665EFF;
	}

	.input-control > div > div:after, .labelled-slider > :last-child > div:after {
		border-bottom: 2px solid #665EFF;
	}

	label {
		font-weight: 600;
		transform: translate(0, 1.5px) scale(1);
	}

	.custom-term-input {
		margin-bottom: 10px !important;
	}

	> div {
		padding-bottom: 16px !important;

		.input-control {
			margin-bottom: 15px;
			position: relative;

			input {
				padding:0;
				font-size: 120%;
				font-weight: 600;
			}
		}

		.slider-control {
			margin-bottom: 10px;
			> label {
				width: 100%;
			}

			.labelled-slider {
				.my-slider {
					display: inline-block;
					vertical-align: middle;
					width: calc(100% - 65px);
				}

				.my-slider + * {
					width: 55px;
					margin-left: 10px;

					input {
						text-align: right;
						font-weight: 600;
						opacity: 0.8;
					}
				}
			}
		}

		.bank-loan-interest {
			button {
				padding: 4px 8px;
				min-width: unset;
				min-height: unset;
				margin-left: 6px;
				text-transform: unset;
			}
		}
	}
}
.pf-card-container {
	.MuiFormLabel-root.Mui-focused {
		color: #665EFF;
	}
	label + .MuiInput-formControl {
		margin-top: 28px;
	}
}
