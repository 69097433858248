.phone-number-input-wrapper {
  .rrui__input-field, .rrui__select__button, .react-phone-number-input__icon {
    border-color: transparent;
  }

  .rrui__input-field:focus, .rrui__select__button:focus, .rrui__select__native-expanded:focus + .rrui__select__button {
    border-color: transparent;
  }

  .react-phone-number-input__icon {
    width: 1.24em;
    height: 1.24em;
  }
}