@media (max-width: 340px){
  div.client-dashboard-coverage-group p {
    font-size: 40px;
  }

  div.client-dashboard-subtitle div {
    font-size: 15px;
    font-weight: 400;
  }

  div.client-dashboard-coverage-div-title > p {
    font-size: 12px;
  }

  div.client-dashboard-coverage-group p {
    font-size: 40px;
  }
}

.client-dashboard-coverage-div {
  // background-color: #eef5ff;
  padding: 8px 0;
  margin: 2px 3px 11px 3px;

  .setting-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px;
  }

  div.client-dashboard-coverage-div-title > p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  div.client-dashboard-coverage-group {
    position: relative;
    width: 33.3333%;
    display: inline-table;
    text-align: center;
  }

  .two-cal .client-dashboard-coverage-group {
    width: 50%;
    margin-top: 8px;
  }

  div.client-dashboard-coverage-group:not(:last-child) {
    border-right: 2px solid #e0e0e0;
  }

  div.client-dashboard-coverage-group h5 {
    font-weight: 400;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #757575;
  }

  div.client-dashboard-coverage-group p {
    font-weight: 400;
    font-size: 38px;
    color: #616161;
    padding-top: 8px;
  }

  div.client-dashboard-coverage-group img {
    height: 33px;
    display: unset;
  }
}