$chosen-client-img-width: 36px;

.chosen-client {
  img {
    width: $chosen-client-img-width
  }

  .client-name {
    flex-grow: 1;
    padding: 4px 6px;
    max-width: calc(100% - #{$chosen-client-img-width});

    .flex-box {
      padding: 3px;
      height: 100%;
      border-radius: 18px;
      box-shadow: 0 2px 6px -1px rgba(57,102,248,.41), 0 1px 6px 0 rgba(57,102,248,.22), 0 1px 3px 0 rgba(57,102,248,.16);

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //font-weight: bold;
        font-size: 16px;
      }
    }
  }
}