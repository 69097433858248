.emergency-export-wrapper {
  position: relative;
  background-color: white;
  font-size: 16px;
  text-align: center;
  height: fit-content;
  color: #333333;
  padding: 5px 0 5px 0;
  width: 100%;
}

.emergency-export-wrapper img {
  vertical-align: unset;
  background: none;
}

.emergency-logo {
  margin-bottom: 20px;
}

.emergency-logo>img {
  width: 360px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.emergency-logo>img.default-logo {
  margin-right: 25px;
}

.emergency-overview {
  margin-bottom: 20px;
  display: grid;
  grid-auto-flow: column;
}

.emergency-overview-right {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
}

.emergency-overview-left {
  .adviser-info {
    background-color: #b3fffe;
    font-size: 19px;
    height: 100%;
  }
}

.emergency-client {
  background-color: var(--new-theme);
  font-size: 26px;
  font-weight: bold;
  padding: 12px 15px 12px 15px;
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
}

.emergency-overview-right-wrapper {
  background-color: #3eeef8;
  height: 100%;
  padding: 18px 0;
}

.emergency-policy-overview-section {}

.emergency-policy-overview-section-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
}

.emergency-policy-overview-section-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emergency-policy-overview-section-subcategory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.emergency-policy-overview-total-number span {
  font-size: 90px;
  font-weight: bold;
  display: block;
  margin: 10px 0 12px 0;
  line-height: 0.7;
}

.emergency-policy-overview-subcategory-total-number span {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
}

.emergency-policy-overview-section-middle span {
  font-size: 45px;
}

.emergency-policy-overview-subcategory-total-number,
.emergency-policy-overview-subcategory-total-label {
  display: inline-block;
}

.emergency-policy-overview-subcategory-total-number {
  width: 50px;
  text-align: center;
}

.emergency-policy-overview-total-number-label>span,
.emergency-policy-overview-subcategory-total-label>span {
  font-size: 14px;
}

.emergency-policy-overview-subcategory-total {
  text-align: left;
}

.emergency-policy-details-insurer:first-child {
  border-top: 2px solid grey;
}

.emergency-policy-details-insurer {
  border-bottom: 2px solid grey;
  margin-left: 20px;
  margin-right: 20px;
}

.emergency-policy-details-insurer-wrapper {
  margin: 30px;
  padding: 0 0 0 20px;
}

.emergency-insurer-left {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
}

.emergency-insurer-right {
  display: inline-block;
  width: 25%;
  vertical-align: middle;
}

.emergency-insurer-name {
  font-size: 23px;
  font-weight: 600;
  text-align: left;
  padding-top: 10px;
  padding-left: 8px;
}

.emergency-insurer-image {
  display: inline-block;
  float: left;
  margin-right: 10px;
  height: 46px;
}

.emergency-insurer-image>img {
  height: 100%;
}

.emergency-insurer-table {
  /*    font-family: 'Poppins';  */
}

table.emergency-insurer-table thead tr th {
  font-weight: 500;
}

table.emergency-insurer-table th,
table.emergency-insurer-table td {
  font-size: 16px;
  padding: 4px 10px 4px 10px;
  color: #333333;
}

table.emergency-insurer-table th,
.emergency-insurer-contact .emergency-insurer-contact-label {
  color: #a2a2a2;
}

table.emergency-insurer-table tr> :first-child {
  width: 50%;
}

table.emergency-insurer-table tr> :nth-child(2) {
  width: 25%;
}

table.emergency-insurer-table tr> :nth-child(3) {
  width: 25%;
}

.emergency-insurer-contact {
  display: flex;
  text-align: left;
  margin-top: 22px;
  padding: 0px 10px;
}

.emergency-insurer-contact-label {
  font-weight: 500;
}

.emergency-insurer-contact-value {
  font-size: 16px;
}

.export-policy-title-wrapper {
  display: inline-flex;
  width: 100%;
}

.export-policy-title {
  width: calc(100% - 16px);
  word-wrap: break-word;
  margin: auto 0;
}

.export-policy-icon-wrapper {
  display: grid;
  float: right;
}

.export-policy-icon-wrapper,
.export-policy-icon {
  width: 16px;
}

.export-policy-icon {
  margin: auto 0;
}

.emergency-insurer-hotline {
  display: inline-block;
  width: 35%;
}

.emergency-insurer-email {
  display: inline-block;
  width: 65%;
  margin-top: -25px;
}

.emergency-insurer-email .emergency-insurer-contact-value:not(.empty),
.emergency-footer {
  color: var(--new-theme);
  width: fit-content;
}

.preview .emergency-insurer-email .emergency-insurer-contact-value:not(.empty),
.preview .emergency-footer {
  border-bottom: 1px solid var(--new-theme);
}

.emergency-insurer-policy-count {
  margin-left: 30px;
  padding: 20px 0;
}

.emergency-insurer-policy-count-number>span {
  font-size: 70px;
  font-weight: bold;
  color: white;
}

.emergency-remark-content {
  /*white-space: pre-wrap;*/
  font-size: 22px;
  /*text-align: left;*/
}

.emergency-remark {
  padding: 20px 10px 20px 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.emergency-contacts {
  text-align: left;
  padding: 20px 10px 20px 30px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid grey;
}

.emergency-contacts-header {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.gobal-emergency-service-header {
  font-size: 15px;
  color: rgb(57, 102, 248);
  margin-bottom: 10px;
  text-align: center;
}

.gobal-emergency-service-hint {
  margin-bottom: 10px;
  margin-top: -10px;
  text-align: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54)
}

.emergency-contacts-marjor-cities-header {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

.emergency-contacts ul {
  font-size: 18px;
}

.emergency-contacts ul li {
  list-style-type: disc;
  margin-top: 10px;
}

.emergency-contacts-label {
  width: 70%;
  display: inline-flex;
}

.emergency-contacts-phone {
  width: 30%;
  display: inline-flex;
  color: var(--new-theme);
}

.emergency-footer {
  margin: 3px auto 20px auto;
}

.emergency-insurer-policy-count-label {
  color: #fff;
}

.emergency-footer-ads {
  margin-top: 8px;
  color: var(--new-theme);
}

.emergency-view-padding {
  padding-top: 60px;
}

.emergency_reference-table .MuiTableHead-root tr th {
  padding-top: 15px;
  padding-bottom: 15px;
}

.emergency_reference-table tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.export-insurance-text {
  display: inline-block;
  vertical-align: text-top;
}

.vhisPlan_export,
.qdapPlan_export {
  padding: 2px;
  margin: 0 0 0 3px;
  font-size: 12px;
  color: #fff !important;
  display: inline-block;
  border-radius: 7px;
}

.vhisPlan_export {
  background: #73bb4b;
}

.qdapPlan_export {
  background: #0fa2b8;
}

.emergency-logo>img.default-logo {
  margin: 10px 0 10px 25px;
}

.emergency-export-wrapper {
  .pro-badge {
    color: white;
    margin-left: 3px;
    //margin-bottom: -8px;
    border-radius: 5px;
    //padding: 3px 3px 6px 3px;
  }
}

.emergency-export {
  #overlay {
    position: relative;
    display: none;
    z-index: 2;
    float: right;

    .edit-info-button {
      padding: 8px !important;
      background-color: var(--new-theme);
      color: white;
      border-radius: 100%;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

  }


  .email {
    color: #a2a2a2 !important;
    text-decoration: none !important;
    border: none !important;
    border-bottom: none !important;
    margin-bottom: 6px;

    text-decoration: underline !important;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
    text-decoration-color: #a2a2a2;
  }

  .left {
    text-align: left !important;
  }

  .right {
    text-align: right !important
  }

  .center {
    text-align: center !important;
  }

  .phone {
    color: var(--new-theme);
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
    text-decoration-color: var(--new-theme);
    margin-bottom: 6px;
  }

  .footer {
    margin: 0px 20px 10px 20px;
    padding: 20px 10px 20px 30px;

    .flex {
      display: flex;
    }

    .text {
      font-size: 12px;
      color: #666;
      text-align: justify;
    }

    .text.pr {
      padding-right: 12px;
    }

    .text.emphasize {
      font-size: 16px;
      color: var(--new-theme);
    }


    .caption {
      font-size: 12px;
      color: #666;
      text-align: center;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .ma {
      margin: auto;
    }
  }
}