.portfolio-view-switch {
  height: 100%;

  > div {
    height: 100%;

    > div {
      max-width: unset;

      button {
        height: 100%;
        min-width: unset;
        padding: 0 4px;
        border-radius: 0;
        border: none;
        border-top: none;
        border-left: 2px solid lightgrey;
        border-right: 2px solid transparent;

        .text {
          font-size: 130%;
          line-height: 1;
        }
      }
    }

    > div:not(:last-child) button {
      border-bottom: 2px solid lightgrey;
    }
  }
}