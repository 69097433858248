.user-missions-popover {
  --chip-border-radius: 12px;
  --chip-padding: 0 12px;
  --chip-color: #ffa62b;

  > div[tabindex="-1"] {
    overflow-y: hidden;

    //.title {
    //  padding: 8px 16px 0 16px;
    //}

    p.title-typo {
      font-size: 125%;
    }

    .select {
      [role="button"] {
        padding-left: 16px;

        span {
          font-size: 125%;
          color: #3966f8;
        }
      }

      .more {
        right: 5px;
        position: absolute;
        pointer-events: none;
        box-shadow: var(--my-shadow);
        border-radius: var(--chip-border-radius);
        background-color: var(--chip-color);
        color: #fff;
        font-weight: 500;

        span {
          padding: var(--chip-padding);
          font-size: 12px;
        }
      }

      .hide-svg {
        .more {
          display: none;
        }
      }
    }

    .reward {
      color: var(--chip-color);
      font-weight: bold;

      span {
        font-size: 14px;
      }
    }

    .incomplete-mission-list, .gained-rewards {
      overflow: hidden auto;
      min-width: 300px;
      //max-height: calc(100vh - 235px);
    }

    @media (max-width: 320px) {
      .incomplete-mission-list, .gained-rewards {
        min-width: 285px;
      }
    }

    .mission-list {
      .mission {
        > div:first-child {
          padding-right: calc((100% - 16px) / 3);
          padding-bottom: 0;

          > div {
            margin-bottom: 0;
          }

          p {
            font-size: inherit;
            line-height: inherit;
          }

          svg {
            height: inherit;
            line-height: inherit;
          }
        }

        > div:nth-child(2) {
          right: 12px;
          line-height: 1;
        }
      }
    }

    .gained-rewards {
      padding: 8px 0;

      .title-typo, .total-reward-entry {
        padding: 0 12px 0 16px;
      }

      .total-reward-entry {
        button {
          background: var(--chip-color);
          border-radius: var(--chip-border-radius);
          padding: var(--chip-padding);
        }
      }

      .completed-mission-list {
        width: 100%;
      }
    }
  }
}