/* Safari 4.0 - 8.0 */
@-webkit-keyframes trackSaveAnimation {
	from {
		background-color: rgb(255, 175, 25);
		;
	}

	to {
		background-color: #ff6a74;
	}
}

/* Standard syntax */
@keyframes trackSaveAnimation {
	from {
		background-color: rgb(255, 175, 25);
		;
	}

	to {
		background-color: #ff6a74;
	}

}

.client-entry:before {
	content: none !important;
}

.client-entry {
	border-width: 0 4px 0 4px;
	border-style: solid;
	border-radius: 6px !important;
	//margin: 5px 0 !important;
	//box-shadow: 0 3px 15px 0 rgba(109, 109, 109, 0.27) !important;

	.button-text-badge {
		>span {
			top: -1px;
			right: -2px;
			min-width: 6px;
			height: 6px;
		}
	}

	.pro-text {
		font-weight: 600;
		color: #FFCC00;
		font-size: 68%;
	}

	button.s-grey:not([disabled]) {
		color: #00000080;
	}

	button.action-button {
		padding: 3px;
		line-height: 1;
		min-width: unset;
		text-transform: unset;
		font-size: 13.5px;
	}

	button.adviser-action {
		color: grey;

		font-weight: normal;
	}

	button.adviser-action-highlight {
		// margin-left: 3px;
		font-weight: bolder;
	}

	button.small {
		font-size: 75%;
		line-height: normal;
	}

	.client-padding-boxes {
		padding: 0 8px 0;
	}

	.client-border-first {
		border-radius: 8px;
		box-shadow: 0 2px 6px -1px #5ee09238, 0 1px 6px 0 #5ee09238, 0 1px 3px 0 #5ee09228;
	}

	.client-border-second {
		border-radius: 8px;
		box-shadow: 0 2px 6px -1px #ffaf1938, 0 1px 6px 0 #ffaf1938, 0 1px 3px 0 #ffaf1928;
	}

	.client-border-third {
		border-radius: 8px;
		box-shadow: 0 2px 6px -1px #3966f838, 0 1px 6px 0 #3966f838, 0 1px 3px 0 #3966f828;
	}

	.track_save {
		background-color: rgb(255, 175, 25);
		-webkit-animation-name: trackSaveAnimation;
		/* Safari 4.0 - 8.0 */
		-webkit-animation-duration: 1s;
		/* Safari 4.0 - 8.0 */
		animation-name: trackSaveAnimation;
		animation-duration: 1s;
		animation-iteration-count: infinite;
	}

	@media (max-width: 326px) {
		.client-padding-boxes {
			padding: 0 4px;
		}

		.client-border-second {
			margin-right: 0;
		}

		.track_save {
			padding: 2px 5px 2px 5px;
			margin: 0 0 0 5px;
			font-size: 12px;
		}
	}

	@media (max-width: 370px) {
		.track_save {
			padding: 2px 6px 1px 6px;
			margin: 0 0 0 5px;
		}
	}

	.summary {
		padding: 0 7px 0 5px;
		border-width: 0 0 0 3px;
		border-style: solid;
		min-height: 48px !important;

		> :first-child {
			margin: 0 !important;
			align-items: center;
		}

		> :nth-child(2) {
			padding: 5px 0 !important;
			margin-right: -5px;
			position: relative;
			width: 30px;
			height: 30px;

			svg {
				font-size: 30px;
			}
		}

		.all__icons--wrapper {
			width: 100%;
			background: #fff;
			height: 46px;
			margin: 0 !important;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;

			img {
				height: 20px;
				margin: 0 6px;
			}

			.rightpull {
				>div {
					display: inline-flex;
				}
			}
		}
	}

	.details {
		.collapseup {

			.collapseup__left>*,
			.collapseup__right>* {
				padding: 8px 8px;
			}

			.collapseup__left {
				.info {
					display: inline-flex;

					.client-disc-btn {
						margin: 0 10px 0 0 !important;
					}
				}
			}
		}

		.collapsedown {
			.shortcut {
				display: inline-block;
			}

			.collapsedown__left {
				border-radius: 8px;

				.last-meeting {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.msg-opt {
						width: 20px;
						height: 20px;
						cursor: pointer;
					}
				}
			}

			.collapsedown__right {
				border-radius: 8px;
				padding: 0;
				// width: 50%;
				margin-left: 5px;
			}

			.even-margin {

				//display: flex;
				> :not(:first-child):not(:last-child) {
					margin: 0 6px;
				}
			}

			.tracking {
				position: relative;
				line-height: normal;

				.material-ui-toggle {
					right: -7px;
				}

				.material-ui-toggle,
				.track_save {
					float: right;
					padding: 5px;
				}
			}

			.client-shortfall-titles {
				width: unset;
				text-align: right;
				margin-right: 5px;

				.client-shortfall-title-main {
					margin: 0;
				}
			}
		}
	}

	button.client-disc-btn {
		color: #fff !important;
		margin: 0 !important;
	}
}

.client-tracking-item {
	border-right: 4px solid transparent;
	margin-right: -2px;
	border-bottom-right-radius: 4px;
	// box-shadow: 0 -2px 6px -1px #3966f868, 0 -1px 6px 0 #3966f838, 0 -1px 3px 0 #3966f828;

	.clientDetail {
		display: inline-flex;
		width: 100%;
		padding: 8px 8px;

		.clientName,
		.clientList {
			width: 50%;
		}
	}
}


.client-expand-img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(50% - 20px);
	margin: 10px;
}

.client-expand-img {
	display: block;
	object-fit: cover;
	height: 100%;
	width: 23px;
	margin-bottom: 4px;
}

.client-expand-img-contact-btn {
	display: block;
	object-fit: cover;
	height: 100%;
	width: 23px;
	margin-bottom: -3px;
	margin-right: 5px;

	@media (max-width: 1024px) {
		display: block;
		object-fit: cover;
		height: 100%;
		width: 23px;
		margin-bottom: 0px;
	}
}

.client-page-expansion-dot-btn {
	font-size: 3.5rem !important;
}

.client-page-expan-dot-spd-dialog {
	>.MuiFab-primary {
		/* color: #ffffff; */
		background-color: #ffffff !important;
	}

	.MuiFab-root {

		box-shadow: none !important;

		.MuiSpeedDial-root {

			.MuiSpeedDialAction-staticTooltip {
				.staticTooltipLabel {
					box-shadow: 0 2px 6px -1px #00000018, 2px 3px 5px 0 #00000018, 0 1px 3px 0 #00000018 !important;
				}

				.MuiSpeedDialAction-fab {
					box-shadow: 0 2px 6px -1px #00000018, 2px 3px 5px 0 #00000018, 0 1px 3px 0 #00000018 !important;

				}
			}
		}
	}


}

.client-page-expan-dot-spd-dialog>.MuiFab-primary:hover {
	/* color: #ffffff; */
	background-color: #ffffff !important;
}

// .MuiSpeedDial-actions :not(.MuiSpeedDial-actionsClosed){
// 	// background-color: #8a3a3a !important;
// 	border-style: groove;
// }

#MuiSpeedDial-actions {
	// background-color: #8a3a3a !important;
	border-style: groove !important;
}

.client-page-expansion-dot> {
	div[role='tooltip'] {
		z-index: 1;
		width: 138px;
		// transform: translateX(670px) !important;
	}
}


.mb-0 {
	margin-bottom: 0px !important;
}

.mr-5 {
	margin-right: 5px;
}

.tracking-off {
	color: #000;
}

.familyLabel {
	border: 1.1px solid;
	border-radius: 10px;
	padding: 0 6px;
	font-size: 11px;
	margin-left: 5px;
	min-width: auto;
	color: #ff570e;
	@media (max-width: 600px) {
		max-width: 60px;
        overflow: hidden;
  		white-space: nowrap;
  		text-overflow: ellipsis;
    }
}