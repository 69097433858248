.partner-list::-webkit-scrollbar {
    -webkit-appearance: none;
}

.partner-list {
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow: scroll;
    padding-top: 70px;
    padding-bottom: 50px;

    @media screen and (min-width: 960px) {
        padding-bottom: 10px;
    }

    background-color: white;
}