.adviser-profile {
	.corner-tri-wrapper {
		top: 50px !important;
	}
	.slider-btn {
		padding-left: 5px;
		padding-right: 5px;
	}
	.top-label {
		padding-left: 6px;
	}
	.form-group {
		margin-top: 10px;
	}
	.sociallists ul li {
		width: 50%;
		float: left;
	  }
	.next-btn {
		position: absolute;
		bottom: 0;
		width: 50%;
	}
}