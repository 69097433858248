.not-found-wrapper {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	p, button {
		margin-bottom: 10px;
	}

	img {
		width: 100%;
	}
}

.not-found-container {
	width: 100vw;
	position: fixed;
	overflow: scroll;
	left: 0;
	background-color: white;
}