button.sync-input {
  position: absolute;
  top: 8px;
  right: 8px;
  min-width: unset;
  padding: 2px 6px;
  font-size: 80%;
  z-index: 1;

  svg {
    font-size: 125%;
  }
}