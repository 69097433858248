.toolbar-wrapper {
  width: 100%;

  > div {
    border-radius: 5px 5px 0 0;
    box-shadow: 0 1px 3px 0 rgba(220,220,220,1);
    background: #fbfbfb;
  }

  button.draftJsToolbar__button__qi1gf {
    padding: 5px 0;
  }

  button.text {
    padding-top: 0;
    padding-bottom: 0;
  }
}