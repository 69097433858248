.register-details {
  padding: 20px 16px 30px 16px;

  .logo-wrapper {
    img {
      max-height: 50px;
    }
  }

  .language.button-options {
    margin: 8px 0;
  }

  .rrui__input-field, .rrui__select__button, .react-phone-number-input__icon {
    border: 0;
  }

  .react-phone-number-input__icon {
    width: 1.24em;
    height: 1.24em;

    image {
      vertical-align: unset;
    }
  }

  .rrui__select__options {
    z-index: 2;
  }

  .checkbox {
    padding: 0 0 0 6px;
    margin-top: 0;
    margin-bottom: 0;

    span {
      font-size: 90%;
      text-transform: unset;
      line-height: 15px;

      a {
        color: inherit;
      }
    }
  }
}