.horizontal-fix {
  min-height: 100vh;
  height: unset !important;
}

.horizontal-fix .flex-wrap {
  min-height: 98vh;
  height: unset !important;
}

.fade-in {
  animation: fadeIn ease 8s;
  -webkit-animation: fadeIn ease 8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
