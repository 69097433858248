.analysis-overall {
  padding: 0 5px 5px;

  .analysis-input-panel {
    margin-bottom: 10px;
  }

  .cardAnalysis.overall-input-shortcuts {
    position: relative;
    padding: 10px;

    .coverage label {
      font-weight: normal;
      font-size: inherit;
    }

    .overall-page-profile-button {
      min-width: 100px !important;
      max-width: 100px !important;
      padding: 6px !important;
      margin: 5px 0px !important;
    }

    .overall-page-profile-button span a {
      color: white !important;
    }
  }

  .Outer.overall-radar {
    max-width: 388px !important;
  }
}