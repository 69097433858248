$primary-main: #3966f8;
$primary-light: #7bc9ff;

$biz-page-blue-1: #3966f8;
$biz-page-blue-2: #92BEF6;
$biz-page-blue-3: #7FB5F5;
$biz-page-blue-4: #97E9F9;
$biz-page-blue-5: #69CBFA;
$biz-page-blue-6: #3966f8;
$biz-page-blue-background: linear-gradient(228.35deg, #85D3FB 1.01%, #F1FAFF 71.66%);

$biz-page-orange-1: #F08E4C;
$biz-page-orange-2: #F3C590;
$biz-page-orange-3: #F4B26B;
$biz-page-orange-4: #FC9B65;
$biz-page-orange-5: #F3C590;
$biz-page-orange-6: #DF933B;
$biz-page-orange-background: linear-gradient(228.35deg, #FFE0BB 1.01%, #FFF8F0 71.66%);

$biz-page-violet-1: #5659B8;
$biz-page-violet-2: #AEA8FF;
$biz-page-violet-3: #002378;
$biz-page-violet-4: #D6CEFF;
$biz-page-violet-5: #9C98FF;
$biz-page-violet-6: #6654C7;
$biz-page-violet-background: linear-gradient(228.35deg, #C4B6ED 1.01%, #F6F6F6 71.66%);

$biz-page-green-1: #13BA65;
$biz-page-green-2: #b3dfbd;
$biz-page-green-3: #0AA050;
$biz-page-green-4: #ADE3BA;
$biz-page-green-5: #4C9F4C;
$biz-page-green-6: #1EA858;

$biz-page-green-background: linear-gradient(228.35deg, #C1EFCB 1.01%, #C1EFCB 1.02%, #F3FFF3 71.66%);

$biz-page-earth-1: #E0C2A5;
$biz-page-earth-2: #B3977B;
$biz-page-earth-3: #886E53;
$biz-page-earth-4: #5E472F;
$biz-page-earth-5: #4D3820;
$biz-page-earth-6: #937C65;
$biz-page-earth-background: linear-gradient(228.35deg, #EFE2CD 1.01%, #F4F2EF 71.66%);

:root {
  --primary-main: #{$primary-main};
  --primary-light: #{$primary-light};

  --biz-page-blue1: #{$biz-page-blue-1};
  --biz-page-blue2: #{$biz-page-blue-2};
  --biz-page-blue3: #{$biz-page-blue-3};
  --biz-page-blue4: #{$biz-page-blue-4};
  --biz-page-blue5: #{$biz-page-blue-5};
  --biz-page-blue6: #{$biz-page-blue-6};
  --biz-page-blue-background: #{$biz-page-blue-background};

  --biz-page-orange1: #{$biz-page-orange-1};
  --biz-page-orange2: #{$biz-page-orange-2};
  --biz-page-orange3: #{$biz-page-orange-3};
  --biz-page-orange4: #{$biz-page-orange-4};
  --biz-page-orange5: #{$biz-page-orange-5};
  --biz-page-orange6: #{$biz-page-orange-6};
  --biz-page-orange-background: #{$biz-page-orange-background};

  --biz-page-violet1: #{$biz-page-violet-1};
  --biz-page-violet2: #{$biz-page-violet-2};
  --biz-page-violet3: #{$biz-page-violet-3};
  --biz-page-violet4: #{$biz-page-violet-4};
  --biz-page-violet5: #{$biz-page-violet-5};
  --biz-page-violet6: #{$biz-page-violet-6};
  --biz-page-violet-background: #{$biz-page-violet-background};

  --biz-page-green1: #{$biz-page-green-1};
  --biz-page-green2: #{$biz-page-green-2};
  --biz-page-green3: #{$biz-page-green-3};
  --biz-page-green4: #{$biz-page-green-4};
  --biz-page-green5: #{$biz-page-green-5};
  --biz-page-green6: #{$biz-page-green-6};
  --biz-page-green-background: #{$biz-page-green-background};

  --biz-page-earth1: #{$biz-page-earth-1};
  --biz-page-earth2: #{$biz-page-earth-2};
  --biz-page-earth3: #{$biz-page-earth-3};
  --biz-page-earth4: #{$biz-page-earth-4};
  --biz-page-earth5: #{$biz-page-earth-5};
  --biz-page-earth6: #{$biz-page-earth-6};
  --biz-page-earth-background: #{$biz-page-earth-background};
}

:export {
  primaryMain: $primary-main;
  primaryLight: $primary-light;

  bizPageBlue1: $biz-page-blue-1;
  bizPageBlue2: $biz-page-blue-2;
  bizPageBlue3: $biz-page-blue-3;
  bizPageBlue4: $biz-page-blue-4;
  bizPageBlue5: $biz-page-blue-5;
  bizPageBlue6: $biz-page-blue-6;
  bizPageBlueBackground: $biz-page-blue-background;

  bizPageOrange1: $biz-page-orange-1;
  bizPageOrange2: $biz-page-orange-2;
  bizPageOrange3: $biz-page-orange-3;
  bizPageOrange4: $biz-page-orange-4;
  bizPageOrange5: $biz-page-orange-5;
  bizPageOrange6: $biz-page-orange-6;
  bizPageOrangeBackground: $biz-page-orange-background;

  bizPageViolet1: $biz-page-violet-1;
  bizPageViolet2: $biz-page-violet-2;
  bizPageViolet3: $biz-page-violet-3;
  bizPageViolet4: $biz-page-violet-4;
  bizPageViolet5: $biz-page-violet-5;
  bizPageViolet6: $biz-page-violet-6;
  bizPageVioletBackground: $biz-page-violet-background;

  bizPageGreen1: $biz-page-green-1;
    bizPageGreen2: $biz-page-green-2;
    bizPageGreen3: $biz-page-green-3;
    bizPageGreen4: $biz-page-green-4;
    bizPageGreen5: $biz-page-green-5;
    bizPageGreen6: $biz-page-green-6;
    bizPageGreenBackground: $biz-page-green-background;

  bizPageEarth1: $biz-page-earth-1;
  bizPageEarth2: $biz-page-earth-2;
  bizPageEarth3: $biz-page-earth-3;
  bizPageEarth4: $biz-page-earth-4;
  bizPageEarth5: $biz-page-earth-5;
  bizPageEarth6: $biz-page-earth-6;
  bizPageEarthBackground: $biz-page-earth-background;
}

.c-p-m {
  color: var(--primary-main);
}
