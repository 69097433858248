.files-upload-dialog {
	.wrapper {
		cursor: pointer;
		padding: 24px;
		text-align: center;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

		svg {
			color: grey;
			font-size: 56px;
		}
	}

	img {
		max-width: 100%;
	}
}

.file-items-container {
	margin: 0 0 0 auto;
  max-width: 500px;
  justify-content: center;
}

.file-item-container {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

.file-button {
  padding: 6px 8px !important;
}

.upload-link-input {
  input {
    font-size: 12px;
    padding: 10px 12px;
  }
}

.upload-link-separator {
  text-align: center;
  margin-bottom: 8px;
}

.upload-button-container {
  width: 100%;
  display: flex;
  padding-bottom: 8px;
  justify-content: center;
  // TODO: align consistent breakpoints?
  @media(max-width: 600px) {
    justify-content: flex-end;
  }
}

.upload-button {
  padding: 6px 8px !important;

  @media(max-width: 600px) {
    height: 23px !important;
    min-width: 60px;
    margin: 4px;
    color: #ccc;
    border-width: 1px;
    border-radius: 12px;
    border-color: #ccc;
    text-transform: none;
    font-size: 1.35rem;
    font-weight: 400;
    padding: 0 6px;
    min-height: 20px!important;
  }

}

.file-item-title-container {
  flex: 1;
  text-align: left;
}

.file-item-title {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media(max-width: 768px) {
      max-width: 200px;
  }
}

.file-item-close-button {
  // TODO: need a common theme styling?
  padding: 0 !important;
}

.file-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-preview-container {
  width: 80%;
  height: 80%;
}

.file-preview-iframe {
  width: 100%;
  height: 100%;
}

.file-preview-close-button {
  color: white !important;
  position: absolute !important;
  top: 16px;
  right: 16px;
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
}

.attachment-icon {
  transform: rotate(-90deg);
}
